/* eslint-disable import/no-cycle */
import { combineReducers } from 'redux';
import spikeEditor from './spike-editor';
import spikeEditorSpikeGraph from './spike-editor-spike-graph';
import maps from './maps';
import gpsAutoCorrection from './gps-auto-correction';
import readingsAutoCorrection from './readings-auto-correction';
import job from './job';
import admin from './admin';
import editorToolbar from './editToolbarReducers/editorToolbar';
import messageBox from './message-box';
import readings from './readings';
import tabs from './tabs';
import segmentGapAnalysis from './segment-gap-analysis';
import reports from './reports';
import additionalSurveys from './additionalSurveys';
import overlap from './overlap';
import stationAlignment from './stationAlignment';
import developerMode from './developerMode';
import uploader from './uploader';
import kmz from './kmz.reducer';
import DdbCustomers from '../slices/DdbCustomers/reducer';
import DdbCustomersEntities from '../slices/DdbCustomersEntities/reducer';

export default combineReducers({
	spikeEditor,
	spikeEditorSpikeGraph,
	maps,
	gpsAutoCorrection,
	readingsAutoCorrection,
	admin,
	job,
	editorToolbar,
	messageBox,
	readings,
	tabs,
	segmentGapAnalysis,
	reports,
	additionalSurveys,
	overlap,
	stationAlignment,
	developerMode,
	uploader,
	kmz,
	DdbCustomers,
	DdbCustomersEntities
});
