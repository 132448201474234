import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { getUserName } from '../../../util/user';
import { getApplications as getApplicationsAction } from '../../redux/actions';

import './LeftPanel.scss';

const miniTitleRegex = /(?<first>(cis)|.)(.*)(((?<v>v)iew)|((?<l>l)ine))/i;

function getMiniTitle(title) {
	if (miniTitleRegex.test(title)) {
		const {
			groups: { first, v, l }
		} = miniTitleRegex.exec(title);
		return `${first}${v || l}`;
	}
	return title;
}

const mapStateToProps = state => {
	const {
		main: { applications }
	} = state;
	return { applications };
};
const actionCreators = {
	getApplications: getApplicationsAction
};
const mapDispatchToProps = dispatch =>
	bindActionCreators(actionCreators, dispatch);

const AppItem = ({ app }) => {
	const { title, url, icon } = app;
	return (
		<li className="menu--item">
			<Link to={`/${url}`} data-index="1" className="menu--link" title={title}>
				<i className={`${icon} left-app`} />
				<span className="menu--label">{title}</span>

				<span className="mini-title">{getMiniTitle(title)}</span>
			</Link>
		</li>
	);
};

const LeftPanel = ({ getApplications, applications }) => {
	const [collapsed, setCollapsed] = useState(true);
	const collapsableClassName = useMemo(() => {
		const mainBody = document.querySelector('.main-body');
		if (collapsed) {
			mainBody.classList.add('main-body-minify');
			return 'vertical_nav__minify';
		}
		mainBody.classList.remove('main-body-minify');
		return 'menu--subitens__opened';
	}, [collapsed]);
	useEffect(() => {
		if (applications.length === 0) {
			getApplications();
		}
	}, []);
	const username = useMemo(() => {
		return getUserName();
	}, []);
	const collapseMenuClick = useCallback(() => {
		setCollapsed(!collapsed);
	}, [collapsed, setCollapsed]);
	return (
		<nav className={`vertical_nav ${collapsableClassName}`}>
			<ul className="menu">
				<li className="menu--item">
					<Link to="/Account/Settings" className="menu--link" title="Profile">
						<i className="menu--icon icon-icon_login" />
						<span className="menu--label">{username}</span>
					</Link>
				</li>
				<li className="menu--item">
					<a
						href="/Applications"
						data-index="0"
						className="menu--link"
						title="AIP Apps"
					>
						<i className="menu--icon  fa fa-home" />
						<span className="menu--label">HOME</span>
					</a>
				</li>
				{applications.map(app => (
					<AppItem key={`leftmenu_app_${app.title}_${app.url}`} app={app} />
				))}
			</ul>
			<button
				type="button"
				className="collapse_menu"
				onClick={collapseMenuClick}
			>
				<i className="collapse_menu--icon  fa fa-fw" />
				<span className="collapse_menu--label">Hide Menu</span>
			</button>
		</nav>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(LeftPanel);
