/* eslint-disable import/no-cycle */
import ajax from '../../../../scanline/utils/ajax';
import { getAccessToken } from '../../../../scanline/utils/token';

const jobListUtils = {
	getJobs(customer, callback) {
		const accessToken = getAccessToken();
		if (accessToken) {
			ajax(
				`/jobs/${encodeURI(customer)}`,
				null,
				'GET',
				(err, res) => {
					if (err) {
						callback(err);
					} else {
						callback(null, res);
					}
				},
				undefined,
				'fieldline'
			);
		}
	},

	getJobMetadata(jobId, lineName, callback) {
		const accessToken = getAccessToken();
		if (accessToken) {
			ajax(
				`/jobMetadata/${encodeURI(jobId)}/${encodeURIComponent(lineName)}`,
				null,
				'GET',
				(err, res) => {
					if (err) {
						callback(err);
					} else {
						callback(null, res);
					}
				},
				undefined,
				'fieldline'
			);
		}
	},

	getJobsMetadata(jobs, callback) {
		const accessToken = getAccessToken();
		if (accessToken) {
			ajax(
				'/jobsmetadata',
				JSON.stringify(jobs),
				'POST',
				(err, res) => {
					if (err) {
						callback(err);
					} else {
						callback(null, res);
					}
				},
				undefined,
				'fieldline'
			);
		}
	},

	getLines(jobId, lineName, index, callback) {
		const accessToken = getAccessToken();
		if (accessToken) {
			ajax(
				`/lines/${encodeURI(jobId)}/${encodeURIComponent(
					lineName
				)}?index=${encodeURI(index)}`,
				null,
				'GET',
				(err, res) => {
					if (err) {
						callback(err);
					} else {
						callback(null, res);
					}
				},
				undefined,
				'fieldline'
			);
		}
	},

	getZip(job, type, callback) {
		ajax(
			'/zipfiles',
			JSON.stringify({ job, type }),
			'POST',
			(err, res) => {
				if (err) {
					callback(err);
				} else {
					callback(null, res);
				}
			},
			undefined,
			'fieldline'
		);
	},

	downloadFinalReport(job, callback) {
		ajax(
			'/downloadFinalReport',
			JSON.stringify({ job }),
			'POST',
			(err, res) => {
				if (err) {
					callback(err);
				} else {
					callback(null, res);
				}
			},
			undefined,
			'fieldline'
		);
	},

	getCustomers(callback) {
		const accessToken = getAccessToken();
		if (accessToken) {
			ajax(
				'/customers',
				null,
				'GET',
				(err, res) => {
					if (err) {
						callback(err);
					} else {
						callback(null, res);
					}
				},
				undefined,
				'fieldline'
			);
		}
	}
};

export default jobListUtils;
