/* eslint-disable import/prefer-default-export */

import { createPromiseRequest } from '../../../../../admin/api/util/createPromiseRequest';
import { hasAccessToken } from '../../../../../scanline/utils/token';

export const listDdbCustomers = () => {
	const url = '/doc/customers';

	if (hasAccessToken()) {
		const fetch = createPromiseRequest({
			url,
			method: 'GET'
		});

		return fetch();
	}
	return Promise.reject(new Error('Missing token'));
};
