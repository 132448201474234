/*
 ** This is primarily used to update data for the readings (and skips) tab
 ** We are using web workers to perform all heavy logic
 */

import { debounce } from 'lodash';

// eslint-disable-next-line import/no-webpack-loader-syntax, import/extensions, import/no-unresolved
// import ReadingWorker from 'workerize-loader?inline!./workers/readings.worker.js';

import {
	getConvertedData,
	getUpdateFilteredReadings,
	deriveSkipReadings,
	getBoundsByFile,
	getBuffersByFile
} from './workers/readings.worker';

// eslint-disable-next-line import/no-cycle
import { setConvertedReadingsForAdditionalSurvey } from './additionalSurveys';
// eslint-disable-next-line import/no-cycle
import { readingMultiplierByName } from './magic-spike-editor';
import ReadingsUtils from './util/readings';

// const readingWorker = ReadingWorker();

const NS_MAPS = 'CISVIEW_READINGS_';

export const SET_CONVERTED_READINGS = `${NS_MAPS}SET_CONVERTED_READINGS`;
const setConvertedReadings = (
	convertedReadings,
	filteredReadings,
	indexOfSavingReading,
	convertedReadingsByFile,
	skipReadings,
	unsortedConvertedReadings,
	unsortedConvertedReadingsByFile,
	boundsByFile,
	buffersByFile
) => {
	return {
		type: SET_CONVERTED_READINGS,
		payload: {
			convertedReadings,
			filteredReadings,
			skipReadings,
			indexOfSavingReading,
			convertedReadingsByFile,
			unsortedConvertedReadings,
			unsortedConvertedReadingsByFile,
			boundsByFile,
			buffersByFile
		}
	};
};

export const SET_UNCONVERTED_READINGS = `${NS_MAPS}SET_UNCONVERTED_READINGS`;
export const setUnsortedConvertedReadings = unsortedConvertedReadingsByFile => ({
	type: SET_UNCONVERTED_READINGS,
	payload: { unsortedConvertedReadingsByFile }
});

export const RESET_COMPUTED_INDEX = `${NS_MAPS}RESET_COMPUTED_INDEX`;
const setResetComputedIndex = () => ({
	type: RESET_COMPUTED_INDEX
});

export const SET_SCROLL_POSITION = `${NS_MAPS}SET_SCROLL_POSITION`;
const setScrollPosition = (
	readingsView,
	scrollUserText,
	scrollToIndex,
	readingIndex,
	surveyType
) => ({
	type: SET_SCROLL_POSITION,
	payload: {
		readingsView,
		scrollUserText,
		scrollToIndex,
		readingIndex, // The reading index and scrollToIndex might be different if we added padding to scrollToIndex
		surveyType
	}
});

export const RESET_SCROLL_POSITION = `${NS_MAPS}RESET_SCROLL_POSITION`;
const setResetScrollPosition = (readingsView, surveyType) => ({
	type: RESET_SCROLL_POSITION,
	payload: {
		readingsView,
		surveyType
	}
});

export const SET_READING_TABLE_SCROLL_INDEX = `${NS_MAPS}SET_READING_TABLE_SCROLL_INDEX`;
const setReadingTableScrollIndex = (readingsView, stopIndex) => ({
	type: SET_READING_TABLE_SCROLL_INDEX,
	payload: {
		readingsView,
		stopIndex
	}
});

export const SET_CONVERTED_READINGS_SORT = `${NS_MAPS}SET_CONVERTED_READINGS_SORT`;
const setConvertedReadingsSort = (
	sortDirection,
	convertedReadings,
	filteredReadings,
	convertedReadingsByFile,
	unsortedConvertedReadings,
	unsortedConvertedReadingsByFile
) => {
	return {
		type: SET_CONVERTED_READINGS_SORT,
		payload: {
			sortDirection,
			convertedReadings,
			filteredReadings,
			convertedReadingsByFile,
			unsortedConvertedReadings,
			unsortedConvertedReadingsByFile
		}
	};
};

export const SET_FILTERS = `${NS_MAPS}SET_FILTERS`;
const setFilter = (filteredReadings, convertedReadingsByFile) => {
	return {
		type: SET_FILTERS,
		payload: {
			filteredReadings,
			convertedReadingsByFile
		}
	};
};

export const SET_FILTER_TEXT_VALUE = `${NS_MAPS}SET_FILTER_TEXT_VALUE`;
const setFilterValue = filter => {
	return {
		type: SET_FILTER_TEXT_VALUE,
		payload: {
			filter
		}
	};
};

const _seperateConvertedReadingsByFile = (dats, convertedReadings) => {
	const blankConvertedReadingsList = dats.map(() => []);
	const readingsIndexByFileName = {};
	dats.forEach((dat, i) => {
		readingsIndexByFileName[dats.fileName] = i;
	});
	convertedReadings.forEach(convertedReading => {
		const { datIndex } = convertedReading;

		blankConvertedReadingsList[datIndex].push(convertedReading);
	});

	return blankConvertedReadingsList;
};

const _updateReadingsFilter = async (filter, dispatch, getState) => {
	const state = getState();
	const { cisview } = state;
	const { readings, job } = cisview;
	const { convertedReadings } = readings;
	const { data } = job;

	const filteredReadings = await getUpdateFilteredReadings(
		filter,
		convertedReadings
	);

	const convertedReadingsByFile = _seperateConvertedReadingsByFile(
		data,
		convertedReadings
	);

	dispatch(setFilter(filteredReadings, convertedReadingsByFile));
};

// We debounce this logic as it might take a while to finish
const _updateReadingsFilterDebounce = debounce(_updateReadingsFilter, 50);

export const updateReadingsFilter = filter => (dispatch, getState) => {
	// We immediatelly set the filter value so they user can keep typing
	dispatch(setFilterValue(filter));
	// We debounce the logic as it might take a while to finish
	_updateReadingsFilterDebounce(filter, dispatch, getState);
};

export const updateConvertedReadings = (dats, indexOfSavingReading) => async (
	dispatch,
	getState
) => {
	const state = getState();
	const { cisview } = state;
	const { readings } = cisview;
	const { sortDirection, filter } = readings;
	const {
		spikeEditorSpikeGraph: { useMv, defaultMv }
	} = cisview;

	const { convertedReadings, unsortedConvertedReadings } = getConvertedData(
		dats,
		sortDirection
	);

	const filteredReadings = await getUpdateFilteredReadings(
		filter,
		convertedReadings
	);

	const convertedReadingsByFile = _seperateConvertedReadingsByFile(
		dats,
		convertedReadings
	);

	let unsortedConvertedReadingsByFile = _seperateConvertedReadingsByFile(
		dats,
		unsortedConvertedReadings
	);

	if (!useMv && defaultMv) {
		unsortedConvertedReadingsByFile = unsortedConvertedReadingsByFile.map(r =>
			readingMultiplierByName(r, 0.001, [
				'reading1',
				'reading2',
				'originalReading1',
				'originalReading2'
			])
		);
	}
	if (useMv && !defaultMv) {
		unsortedConvertedReadingsByFile = unsortedConvertedReadingsByFile.map(r =>
			readingMultiplierByName(r, 1000, [
				'reading1',
				'reading2',
				'originalReading1',
				'originalReading2'
			])
		);
	}

	const skipReadings = await deriveSkipReadings(convertedReadings);

	setConvertedReadingsForAdditionalSurvey(convertedReadings)(
		dispatch,
		getState
	);

	const readingsGeom = unsortedConvertedReadingsByFile.map(rs =>
		rs.map(({ coordinates, datIndex }) => ({ coordinates, datIndex }))
	);

	const boundsByFile = await getBoundsByFile(readingsGeom);

	const buffersByFile = await getBuffersByFile(readingsGeom);
	dispatch(
		setConvertedReadings(
			convertedReadings,
			filteredReadings,
			indexOfSavingReading,
			convertedReadingsByFile,
			skipReadings,
			unsortedConvertedReadings,
			unsortedConvertedReadingsByFile,
			boundsByFile,
			buffersByFile
		)
	);
};

export const resetComputedIndex = () => dispatch => {
	dispatch(setResetComputedIndex());
};

function _getNewSortDirection(getState) {
	const state = getState();
	const { cisview } = state;
	const { readings } = cisview;
	const { sortDirection } = readings;

	return ReadingsUtils.getNewSortDirection(sortDirection);
}

// logic is now moved to worker
export const updateReadingsSort = () => async (dispatch, getState) => {
	const state = getState();
	const { cisview } = state;
	const { job, readings } = cisview;
	const { data } = job;
	const { filter } = readings;

	const sortDirection = _getNewSortDirection(getState);

	const {
		convertedReadings,
		unsortedConvertedReadings
	} = await getConvertedData(data, sortDirection);
	const filteredReadings = await getUpdateFilteredReadings(
		filter,
		convertedReadings
	);

	const convertedReadingsByFile = _seperateConvertedReadingsByFile(
		data,
		convertedReadings
	);

	const unsortedConvertedReadingsByFile = _seperateConvertedReadingsByFile(
		data,
		unsortedConvertedReadings
	);

	dispatch(
		setConvertedReadingsSort(
			sortDirection,
			convertedReadings,
			filteredReadings,
			convertedReadingsByFile,
			unsortedConvertedReadings,
			unsortedConvertedReadingsByFile
		)
	);
};

const _getScrollIndexFromReadingIndexAndOffset = (
	allReadings,
	readingIndex,
	stopIndex
) => {
	if (!stopIndex) {
		return readingIndex;
	}

	// If we have to scroll down to see the index, add some padding to make sure the row is visible
	let newIndex = readingIndex;
	if (readingIndex > stopIndex) {
		newIndex += 2;
	}

	// ... but if we are at the end of the view, back up
	if (newIndex >= allReadings.length) {
		newIndex = allReadings.length - 1;
	}

	return newIndex;
};

export const onGoToIndex = (
	allReadings,
	readingsView,
	scrollUserText,
	readingIndex,
	surveyType = 'any'
) => (dispatch, getState) => {
	const state = getState();
	const { cisview } = state;
	const { readings } = cisview;
	const { readingViewStopIndexes } = readings;

	const stopIndex = readingViewStopIndexes[readingsView] || 0;

	const scrollToIndex = _getScrollIndexFromReadingIndexAndOffset(
		allReadings,
		readingIndex,
		stopIndex
	);

	dispatch(
		setScrollPosition(
			readingsView,
			scrollUserText,
			scrollToIndex,
			readingIndex,
			surveyType
		)
	);
};

export const resetSelectedReadingIndex = (
	readingsView,
	surveyType = 'any'
) => dispatch => {
	dispatch(setResetScrollPosition(readingsView, surveyType));
};

export const onReadingsTableRowRendered = (
	readingsView,
	stopIndex
) => dispatch => {
	dispatch(setReadingTableScrollIndex(readingsView, stopIndex));
};
