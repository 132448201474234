import { displayAdminRelatedMessage } from './message-box';
/* eslint import/no-cycle:0 */
import JobUtil from '../../utils/JobUtil/JobUtil';

const NS_MAPS = 'cisview_job_';

export const SUBMIT_JOB = `${NS_MAPS}SUBMIT_JOB`;
export const SEARCH_JOB = `${NS_MAPS}SEARCH_JOB`;
export const SUBMIT_EDITED_JOB = `${NS_MAPS}SUBMIT_EDITED_JOB`;

export const SET_ADMIN_DATA = `${NS_MAPS}SET_ADMIN_DATA`;
export const setAdminData = (adminData, data) => {
	return {
		type: SET_ADMIN_DATA,
		payload: { adminData, data }
	};
};

export const setSearchJob = (adminData, data) => {
	return {
		type: SEARCH_JOB,
		payload: { adminData, data }
	};
};

// export const setSubmitJob = (adminData, data) => {
// 	return {
// 		type: SUBMIT_JOB
// 		// payload: { adminData, data }
// 	};
// };

const _savingCompleted = (err, res, item = {}, calledFromPage) => dispatch => {
	dispatch(displayAdminRelatedMessage(err, res, item, calledFromPage));
};

const _checkForMandatoryFields = formdata => {
	if (
		formdata.jobId &&
		formdata.desc &&
		formdata.customer &&
		formdata.entity &&
		formdata.projectManager &&
		formdata.ct &&
		formdata.lineNo &&
		formdata.comment &&
		formdata.assignToDP &&
		formdata.assignToPM
	) {
		return true;
	}
	return false;
};

const _updateAdminChange = formdata => dispatch => {
	// TODO why is this flag needed?
	formdata.flag = 'PM';

	const mandatoryFieldPresent = _checkForMandatoryFields(formdata);
	if (mandatoryFieldPresent) {
		JobUtil.create({ formdata }, (err, res) => {
			if (err) {
				dispatch(_savingCompleted(err, null, { formdata }, 'Create'));
			} else {
				// TODO return responseMessage obj instead from sls.
				const msg = typeof res === 'string' ? res : null;
				dispatch(_savingCompleted(null, msg, { formdata }, 'Create'));
			}
		});
	} else {
		const msg = 'Please select all mandatory fields';
		dispatch(_savingCompleted(null, msg, { formdata }, 'Create'));
	}
};

const _checkMandatoryFields = (
	job,
	customer,
	updatedjob,
	lineNo,
	checkedItems
) => {
	const flagWithMessage = {};

	if (!job || customer === undefined) {
		flagWithMessage.present = false;
		flagWithMessage.message = 'Please Search for the job no. to continue ..';
		return flagWithMessage;
	}

	if (
		job &&
		(lineNo !== undefined ||
			updatedjob ||
			(checkedItems !== undefined && checkedItems.length > 0))
	) {
		flagWithMessage.present = true;
		return flagWithMessage;
	}
	flagWithMessage.present = false;
	flagWithMessage.message = 'Please enter updated Job no or new lines to add.';
	return flagWithMessage;
};

const _updateEditedJob = (
	job,
	customer,
	updatedjob,
	lineNo,
	checkedItems,
	fromJobCard,
	deleteFlag
) => (dispatch, getState) => {
	const mandatoryFieldPresentObj = _checkMandatoryFields(
		job,
		customer,
		updatedjob,
		lineNo,
		checkedItems
	);

	const state = getState();
	const { cisview } = state;
	const { adminData } = cisview.admin;
	const { data } = adminData;
	if (!fromJobCard && !mandatoryFieldPresentObj.present) {
		const msg = mandatoryFieldPresentObj.message;
		dispatch(displayAdminRelatedMessage(null, msg, { job }, 'Update'));
	} else {
		JobUtil.editJob(
			job,
			updatedjob,
			lineNo,
			checkedItems,
			fromJobCard,
			deleteFlag,
			(err, res) => {
				if (err) {
					dispatch(displayAdminRelatedMessage(err, null, { job }, 'Update'));
				} else {
					if (deleteFlag && fromJobCard) {
						console.log('delete from JOb Card');
					}
					if (!fromJobCard) {
						data.existingLineName = data.existingLineName.concat(lineNo);
						data.res = res;
						// TODO return responseMessage obj instead from sls.
					}
				}
				dispatch(setSearchJob(adminData, data));
				dispatch(displayAdminRelatedMessage(null, null, { job }, 'Update'));
			}
		);
	}
};

export const _searchJob = jobNo => (dispatch, getState) => {
	const state = getState();
	const { cisview } = state;
	const { adminData } = cisview.admin;
	JobUtil.list(jobNo, (err, data) => {
		if (err) {
			dispatch(_savingCompleted(err, null, { jobNo }));
		} else {
			let existingLineName = '';
			if (data) {
				if (data.job === undefined) {
					dispatch(
						displayAdminRelatedMessage(
							err,
							`No Jobs found with Job Number ${jobNo}`,
							{ jobNo }
						)
					);
					dispatch(setSearchJob(adminData, data));
				}
				if (data.lines !== undefined) {
					const keys = Object.keys(data.lines);
					keys.forEach(key => {
						existingLineName = `${existingLineName + key}; `;
					});
				}
				data.existingLineName = existingLineName;
				dispatch(setSearchJob(adminData, data));
			}
		}
	});
};

export const submitJob = newJobObject => (dispatch, getState) => {
	_updateAdminChange(newJobObject)(dispatch, getState);
};

export const submitEditedJob = (
	job,
	customer,
	updatedjob,
	lineNo,
	checkedItems,
	fromJobCard,
	deleteFlag
) => (dispatch, getState) => {
	_updateEditedJob(
		job,
		customer,
		updatedjob,
		lineNo,
		checkedItems,
		fromJobCard,
		deleteFlag
	)(dispatch, getState);
};

export const searchJob = jobNo => (dispatch, getState) => {
	_searchJob(jobNo)(dispatch, getState);
};
