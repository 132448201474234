/* eslint-disable import/no-cycle */
/* eslint-disable consistent-return */
/* eslint-disable import/prefer-default-export */
import { selectMyReadingDataStructures } from '../../../../selectors/alignedReadings';
import {
	selectDepolReadings,
	selectDepolReadingsWithChartGaps,
	selectDepolReadingsMap
} from '../../../../selectors/depolReadings';
import { selectSurvey } from '../../../../selectors/surveys';
import { thunkSetSelectedRemediationGroup } from '../ChartsSettings/actions';
import {
	selectThreshold,
	selectUseThreshold,
	selectUseShadow,
	selectUseSawtooth,
	selectChartPrimarySurveyId,
	selectChartDepolSurveyId,
	selectSeriesVisibility,
	selectUseNormalizedDepol,
	selectUseInterpolatedDepol
	// selectBelowCriterionComparator
} from '../ChartsSettings/selectors';

import { computeBelowCriterionDataSets } from './util/computeBelowCriterions/main';

// @todo needs to be improved to work for more survey types
const getReadingKeyForComputeBelowCriterions = (stateApp, chartId, survey) => {
	// const primarySurveyMeta = survey.meta;
	const surveyId = survey.id;
	const identifiers = { chartId, surveyId };
	const useSawtooth = selectUseSawtooth(stateApp, identifiers);
	const surveyType = survey.survey_subtype;

	if (surveyType === 'ON_OFF' || surveyType === 'ON-OFF') {
		const isSawtoothVisible =
			useSawtooth &&
			selectSeriesVisibility(stateApp, identifiers, {
				dataSetKey: survey.id,
				propKey: 'sawtooth'
			});

		const isOnVisible = selectSeriesVisibility(stateApp, identifiers, {
			dataSetKey: survey.id,
			propKey: 'on'
		});

		const isOffVisible = selectSeriesVisibility(stateApp, identifiers, {
			dataSetKey: survey.id,
			propKey: 'off'
		});

		if (isSawtoothVisible && useSawtooth) {
			return 'off';
		}

		if (isOffVisible && !useSawtooth) {
			return 'off';
		}

		if (isOnVisible && !useSawtooth) {
			return 'on';
		}
	}
	return ' ';
};

export const createThunkComputeBelowCriterions = (
	addChartBelowCriterions,
	removeChartBelowCriterions
) => chartId => (dispatch, getState) => {
	dispatch(thunkSetSelectedRemediationGroup(chartId, undefined));
	const { app } = getState();
	const identifiers = { chartId };
	const threshold = selectThreshold(app, identifiers);
	const primarySurveyId = selectChartPrimarySurveyId(app, identifiers);

	if (!primarySurveyId) {
		return undefined;
	}

	const primarySurvey = selectSurvey(app, primarySurveyId);
	const primarySurveyType = primarySurvey.survey_subtype;

	const depolSurveyId = selectChartDepolSurveyId(app, identifiers);

	const useShadow = selectUseShadow(app, identifiers) && !!depolSurveyId;
	const useThreshold = selectUseThreshold(app, identifiers) || !useShadow;

	// PRIMARY READINGS
	const {
		readings: primaryReadings,
		readingsWithChartGaps: primaryReadingsWithChartGaps,
		readingsWithChartGapsIndexMap: primaryReadingsWithChartGapsIndexMap
	} = selectMyReadingDataStructures(primarySurvey, app);

	const primaryReadingKey = getReadingKeyForComputeBelowCriterions(
		app,
		chartId,
		primarySurvey
	);

	// DEPOL READINGS
	let depolSurvey;
	let secondarySurveyType;
	let secondaryReadings;
	let secondaryReadingsWithGaps;
	let secondaryReadingsWithGapsMap;

	if (depolSurveyId) {
		const useNormalizedDepol = selectUseNormalizedDepol(app, identifiers);
		const useInterpolatedDepol = selectUseInterpolatedDepol(app, identifiers);

		depolSurvey = selectSurvey(app, depolSurveyId);
		secondarySurveyType = depolSurvey.survey_subtype;
		secondaryReadings = selectDepolReadings(
			app,
			depolSurveyId,
			useInterpolatedDepol,
			useNormalizedDepol
		);
		secondaryReadingsWithGaps = selectDepolReadingsWithChartGaps(
			app,
			depolSurveyId,
			useInterpolatedDepol,
			useNormalizedDepol
		);
		secondaryReadingsWithGapsMap = selectDepolReadingsMap(
			app,
			depolSurveyId,
			useInterpolatedDepol,
			useNormalizedDepol
		);
	}

	// @todo - get comparaotor key
	// const comparatorKey = selectBelowCriterionComparator(app, chartId);

	dispatch(removeChartBelowCriterions(chartId));

	if (primaryReadingKey) {
		const payload = {
			primary: {
				surveyType: primarySurveyType,
				readings: primaryReadings,
				readingsWithGaps: primaryReadingsWithChartGaps,
				readingsWithGapsIndexMap: primaryReadingsWithChartGapsIndexMap,
				readingKey: primaryReadingKey
			},
			secondary: {
				surveyType: secondarySurveyType,
				readings: secondaryReadings,
				readingsWithGaps: secondaryReadingsWithGaps,
				readingsWithGapsMap: secondaryReadingsWithGapsMap
			},
			threshold,
			useThreshold,
			useShadow,
			comparatorkey: 'GREATER_THAN' // @todo
		};
		// console.log(payload);
		// debugger;
		const belowCriterionDataSets = computeBelowCriterionDataSets(payload);
		// console.log(belowCriterionDataSets);
		// debugger;
		dispatch(addChartBelowCriterions(chartId, belowCriterionDataSets));
	}
};
