import React from 'react';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import { Route, Switch } from 'react-router-dom';

import { detect } from 'detect-browser';
import version from './ReleaseVersion';

import {
	isTokenValid,
	getUserName,
	hasCognitoGroupName
} from '../../../util/user';

import './UserMenu.css';
import { AWS_GROUP_NAMES } from '../../../util/permissions/constants';

const browser = detect();

const NavMenuItem = ({ to, children }) => {
	return (
		<MenuItem
			onClick={() => {
				window.location = to;
			}}
		>
			{children}
		</MenuItem>
	);
};

const ApplicationsLink = () => {
	return (
		<li>
			<a style={{ paddingTop: '6px' }} href="/Applications">
				MY APPLICATIONS
			</a>
		</li>
	);
};

const UserMenu = () => {
	if (!isTokenValid()) {
		return null;
	}
	return (
		<Switch className="username">
			<Route path="/Index">
				<ApplicationsLink />
			</Route>
			<Route exact path="/">
				<ApplicationsLink />
			</Route>
			<Route>
				<DropdownButton
					id="usermenu"
					bsStyle="link"
					title={
						<>
							<i className="menu--icon icon-icon_login" />
							{getUserName()}
						</>
					}
				>
					<NavMenuItem to="/Account/Settings">Profile</NavMenuItem>
					{(hasCognitoGroupName(AWS_GROUP_NAMES.AIP_ADMINS) ||
						hasCognitoGroupName(AWS_GROUP_NAMES.CUSTOMER_ADMINS)) && (
						<NavMenuItem to="/Admin">Settings</NavMenuItem>
					)}
					<NavMenuItem to="/Account/Logoff">Logout</NavMenuItem>
					<NavMenuItem to="https://wiki.aip.aegion.com/">
						Releases and Tutorials
					</NavMenuItem>
					<MenuItem>
						<span className="browserMenu">{`${browser.name} - ${browser.version}`}</span>
					</MenuItem>
					<MenuItem>
						<span className="browserMenu">{`${version}`}</span>
					</MenuItem>
				</DropdownButton>
			</Route>
		</Switch>
	);
};

export default UserMenu;
