import ReadingUtil from 'aegion_common_utilities/lib/ReadingsUtil';
import { GEO_ALGORITHMS } from 'aegion_common_utilities/lib/MapProcessing/config';

import ReadingsUtils from './readings.worker.utils';

export function deriveSkipReadings(convertedReadings) {
	return ReadingsUtils.deriveSkipReadings(convertedReadings);
}

export function getConvertedData(dats, sortDirection) {
	const unsortedConvertedReadings = ReadingsUtils.convertData(dats);

	let convertedReadings = unsortedConvertedReadings;
	if (sortDirection) {
		convertedReadings = ReadingsUtils.sortData(
			convertedReadings,
			sortDirection,
			dats
		);
	}
	return { convertedReadings, unsortedConvertedReadings };
}

export function getUpdateFilteredReadings(filter, convertedReadings) {
	return ReadingsUtils.getUpdateFilteredReadings(filter, convertedReadings);
}

export function getBoundsByFile(convertedReadings) {
	const bounds = ReadingsUtils.getBoundsByFile(convertedReadings);
	return bounds;
}

export function getBuffersByFile(convertedReadings) {
	const buffers = ReadingsUtils.getBuffersByFile(convertedReadings);
	return buffers;
}

export function flattenGpsReadings(json) {
	return ReadingUtil.flattenGpsReadings(json, {
		algorithm: GEO_ALGORITHMS.GEOD
	});
}
