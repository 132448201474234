/* eslint-disable import/no-cycle */
import {
	SET_IS_PIPE_ENABLED,
	SET_IS_POWERLINE_ENABLED,
	SET_IS_CUSTOMER_DATA_ENABLED,
	SET_IS_GROUPING_LAYER_ENABLED,
	SET_IS_GAP_LAYER_ENABLED
} from './actionTypes';

const initialState = {
	dynamicLayerVisibility: {
		pipelines: false,
		powerlines: false,
		customerdata: false,
		groupingLayer: false,
		gapLayers: false
	}
};

const mapsReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_IS_PIPE_ENABLED:
			return {
				...state,
				dynamicLayerVisibility: {
					...state.dynamicLayerVisibility,
					pipelines: payload
				}
			};
		case SET_IS_POWERLINE_ENABLED:
			return {
				...state,
				dynamicLayerVisibility: {
					...state.dynamicLayerVisibility,
					powerlines: payload
				}
			};
		case SET_IS_CUSTOMER_DATA_ENABLED:
			return {
				...state,
				dynamicLayerVisibility: {
					...state.dynamicLayerVisibility,
					customerdata: payload
				}
			};
		case SET_IS_GROUPING_LAYER_ENABLED:
			return {
				...state,
				dynamicLayerVisibility: {
					...state.dynamicLayerVisibility,
					groupingLayer: payload
				}
			};
		case SET_IS_GAP_LAYER_ENABLED:
			return {
				...state,
				dynamicLayerVisibility: {
					...state.dynamicLayerVisibility,
					gapLayers: payload
				}
			};

		default:
			return state;
	}
};
export default mapsReducer;
