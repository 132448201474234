/* eslint-disable import/no-cycle */
import * as MapProcessingUtil from 'aegion_common_utilities/lib/MapProcessingUtil';
import ReadingsUtil from 'aegion_common_utilities/lib/ReadingsUtil';

import JobUtil from '../../../utils/JobUtil/JobUtil';
import { sendEdits } from '../../../utils/Edits/Edits.api';
import SurveyUtil from '../../../utils/SurveyUtil';
import AutoCorrectionUtil from '../../../utils/AutoCorrectionUtil';
import DatFileUtil from '../../../utils/DatFiles/DatFileUtil';

import { setHasFiles, setCISSurvey } from '../tabs';
import { clearEditor } from '../editorToolbar';
import { setSpikeEditorShouldChange } from '../magic-spike-editor';
import { updateConvertedReadings, resetComputedIndex } from '../readings';
import { setReadingsChanged } from '../readings-auto-correction';
import { setSegmentGapReadingsShouldChange } from '../segment-gap-analysis';
import { resetSurveyAlignment, setEditableSurvey } from '../stationAlignment';
import { updateOverlapData } from '../overlap';
import { displayDatSaveMessage } from '../message-box';
import { setSurveyType } from '../spike-editor-spike-graph';
import { setGlobalJobStatus } from '../globalJobStatus';
import { setGlobalAssistingDP } from '../globalAssistingDP';
import { setHistoricalJobs as acSetHistoricalJobs } from '../historicalJobs';
import { getReadingsWithAlignedStationIds } from '../util/station-alignment';

import {
	// General Constants
	COMPLETE,
	// Pure Action Creators
	setGlobalData,
	setGpsReadingsStillLoading,
	setSavingComplete,
	setRemoveDat,
	setDatVisibility,
	setDatTrims,
	setReadingComment,
	setReplaceReading,
	setDeleteReading,
	setDeleteSkip,
	setAddSkip,
	setAddDatFileCount,
	setRemoveDatFileCount,
	setCachedReadingsData
} from './job';
import {
	_getNewDataFromChangedDatFileField,
	_getNewDataFromChangedDatFileFields,
	_getDataFromUpdatingSingleReading,
	_dataHasGpsReadings,
	_getDatFileByIndex,
	_getNewGpsReadingsFromAddingSkip,
	addStationToTrims,
	_getCurrentData,
	_saveJobFromStationAligments,
	_getNewStationsForFlips,
	_sortDatFilesByStartStn,
	_resortGlobalData,
	_getNewStartStationForReverse,
	_getStartStationFromUser,
	_getNewGpsReadingsFromChangingInterval,
	_updateJobWithSingleDatFileChange,
	_additionalStatusChanges,
	_sendSegmentEventForJobStatusChange
} from './util/fileEdits';
import { selectJobId } from '../../selectors/job';

const {
	EditReadings,
	Editing: { EditType }
} = ReadingsUtil;

export const onReadingsChanged = (data, computedIndex) => (
	dispatch,
	getState
) => {
	const state = getState();
	const { cisview } = state;
	const { job } = cisview;
	const { readingsCachedData, globalData } = job;

	// We take a cache of the data so that we don't call this expensive operation multiple times for the same data
	if (readingsCachedData === data) {
		return;
	}

	dispatch(setCachedReadingsData(data));

	const hasFiles = data.length > 0;
	const cisSurvey = SurveyUtil.getCISSurvey(
		data.surveyType || (globalData || {}).surveyType
	);
	dispatch(setHasFiles(hasFiles));
	dispatch(setCISSurvey(cisSurvey));

	updateConvertedReadings(data, computedIndex)(dispatch, getState);
	dispatch(setReadingsChanged(data));
	dispatch(setSegmentGapReadingsShouldChange(data));
	dispatch(resetSurveyAlignment());

	updateOverlapData(data)(dispatch, getState);
};

export const changeDatFileVisibility = (datFile, visible) => (
	dispatch,
	getState
) => {
	const { data, globalData } = _getNewDataFromChangedDatFileField(
		datFile,
		getState,
		'visible',
		visible
	);

	onReadingsChanged(data)(dispatch, getState);

	dispatch(setDatVisibility(data, globalData));
};

export const changeDatFileTrims = (datFile, trims, options = {}) => (
	dispatch,
	getState
) => {
	const { data, globalData } = _getNewDataFromChangedDatFileField(
		datFile,
		getState,
		'trims',
		trims,
		options
	);

	onReadingsChanged(data)(dispatch, getState);

	// TODO: We are actually saving withing the Overlap Component, decide if we need to move to here
	dispatch(setDatTrims(data, globalData));

	return { data, globalData };
};

// This actually deactivates the spinner when all pending changes are saved
export const decrementDatFileSavingCount = () => (dispatch, getState) => {
	const state = getState();
	const { cisview } = state;
	const { job } = cisview;
	const { datFilesBeingSavedCount } = job;

	const newDatFilesBeingSavedCount = datFilesBeingSavedCount - 1;
	const isSaving = newDatFilesBeingSavedCount > 0;
	dispatch(setRemoveDatFileCount(newDatFilesBeingSavedCount, isSaving));
};

export const incrementDatFileSavingCount = () => dispatch => {
	dispatch(setAddDatFileCount());
};

export const _datFileSavingCompleted = (
	err,
	res,
	item = {},
	options = {}
) => dispatch => {
	const { showSaveMessage = true } = options;
	if (showSaveMessage) {
		dispatch(displayDatSaveMessage(err, res, item));
	}

	dispatch(decrementDatFileSavingCount());
};

const saveComplete = (dispatch, getState, datFile, options = {}) => {
	const { disableSuccessMessage = false, saveMessage = null } = options;
	const { data, globalData } = _getNewDataFromChangedDatFileField(
		datFile,
		getState,
		'isEdited',
		false
	);

	if (!disableSuccessMessage) {
		dispatch(displayDatSaveMessage(null, saveMessage, datFile));
	}

	dispatch(decrementDatFileSavingCount());

	dispatch(resetComputedIndex());
	dispatch(setSavingComplete(data, globalData));
	// dispatch(onReadingsChanged(data));
};

const deleteComplete = (dispatch, getState, datFile) => {
	const { data, globalData } = _getNewDataFromChangedDatFileField(
		datFile,
		getState,
		'isDeleted',
		false
	);
	dispatch(displayDatSaveMessage(null, null, datFile));

	dispatch(decrementDatFileSavingCount());
	// Readings haven't changed here so do not call onReadingsChanged(data)(dispatch, getState);
	dispatch(setSavingComplete(data, globalData));
};

const _onSaveError = (dispatch, getState, err, datFile) => {
	dispatch(displayDatSaveMessage(err, null, datFile));

	dispatch(decrementDatFileSavingCount());
};

const sendDeleteReading = async (
	dispatch,
	getState,
	datFile,
	globalData,
	gpsReadingIndex,
	readingsToDeleteCount
) => {
	try {
		dispatch(setAddDatFileCount());
		const { deletedReadings } = await JobUtil.deleteReadings(
			datFile,
			globalData,
			gpsReadingIndex,
			readingsToDeleteCount
		);

		const saveMessage = `Successfully deleted ${deletedReadings} reading(s)`;

		saveComplete(dispatch, getState, datFile, { saveMessage });
	} catch (error) {
		_onSaveError(dispatch, getState, error, datFile);
	}
};

export const save = (options = {}) => (dispatch, getState) => {
	const state = getState();
	const { cisview } = state;
	const { job } = cisview;

	let data;
	let globalData;
	if (options.data && options.globalData) {
		data = options.data;
		globalData = options.globalData;
	} else {
		data = job.data;
		globalData = job.globalData;
	}

	const { computedIndex, disableSuccessMessage = false } = options;

	const { timeuuid } = globalData;

	const promises = data.map(item => {
		if (
			item.isEdited ||
			item.gpsAutoCorrectionInProgress ||
			item.readingsAutoCorrectionInProgress
		) {
			dispatch(setAddDatFileCount());
			return JobUtil.saveGpsReadings(item, timeuuid)
				.then(() => {
					saveComplete(dispatch, getState, item, { disableSuccessMessage });
				})
				.catch(e => {
					_onSaveError(dispatch, getState, e, item);
				});
		}
		if (item.isDeleted) {
			dispatch(setAddDatFileCount());
			return JobUtil.saveGpsReadings(item, timeuuid)
				.then(() => {
					deleteComplete(dispatch, getState, item);
				})
				.catch(e => {
					_onSaveError(dispatch, getState, e, item);
				});
		}

		return Promise.resolve(); // Nothing to do for this file
	});

	// There is a chance that this gets called multiple times - but we cache the data to make sure that we don't run multiple times
	onReadingsChanged(data, computedIndex)(dispatch, getState);

	return Promise.all(promises).then(() => {
		dispatch(clearEditor());
		// TODO: Not sure how this ever worked
		if (!options || options.setData !== false) {
			dispatch(setGlobalData(globalData, data));
		}
	});
};

export const removeDat = datFile => (dispatch, getState) => {
	const state = getState();
	const { cisview } = state;
	const { job } = cisview;
	const { data, globalData } = job;

	const newMasterLST = {};
	Object.keys(globalData.MasterLST).forEach(fileName => {
		if (fileName !== datFile.fileName) {
			newMasterLST[fileName] = globalData.MasterLST[fileName];
		}
	});

	const newGlobalData = {
		...globalData,
		MasterLST: newMasterLST
	};

	const newData = data.filter(({ fileName }) => fileName !== datFile.fileName);

	onReadingsChanged(newData)(dispatch, getState);

	dispatch(setRemoveDat(newGlobalData, newData));
	dispatch(setSpikeEditorShouldChange());
	incrementDatFileSavingCount()(dispatch);
	JobUtil.deleteDatRef(datFile.fileName, globalData.timeuuid, err => {
		if (err) {
			// Reset when there is an error
			dispatch(setGlobalData(globalData, data));
			dispatch(
				_datFileSavingCompleted(err, null, { fileName: datFile.fileName })
			);
		} else {
			dispatch(
				_datFileSavingCompleted(
					null,
					`Successfully deleted ${datFile.fileName}`
				)
			);
		}
	});
};

export const removeAllDatFiles = () => (dispatch, getState) => {
	const state = getState();
	const { cisview } = state;
	const { job } = cisview;
	const { data, globalData } = job;

	const newGlobalData = {
		...globalData,
		MasterLST: []
	};

	const newData = [];

	onReadingsChanged(newData)(dispatch, getState);

	dispatch(setRemoveDat(newGlobalData, newData));
	return new Promise((res, rej) => {
		JobUtil.deleteAllDat(selectJobId(getState()), err => {
			if (err) {
				rej(err);
				// Revert if there was an error
				setGlobalData(globalData, data);
				dispatch(_datFileSavingCompleted(err, null));
			} else {
				res();
				dispatch(_datFileSavingCompleted(null, 'Successfully deleted job'));
			}
		});
	});
};

export const processEdits = (edits, data) => (dispatch, getState) => {
	const {
		cisview: {
			job: {
				globalData: { timeuuid }
			}
		}
	} = getState();
	dispatch(incrementDatFileSavingCount());

	return sendEdits(timeuuid, edits)
		.then(() => {
			dispatch(_datFileSavingCompleted(null, 'Edits Saved Succesfully'));
			onReadingsChanged(data)(dispatch, getState);
		})
		.catch(err => {
			dispatch(_datFileSavingCompleted(err));
			onReadingsChanged(data)(dispatch, getState);
		});
};

export const updateReadingComment = (
	datIndex,
	gpsReadingIndex,
	comments,
	computedIndex
) => (dispatch, getState) => {
	const {
		data,
		globalData,
		datFileName,
		datFileKey
	} = _getDataFromUpdatingSingleReading(
		getState,
		datIndex,
		gpsReadingIndex,
		{ comments } // update value.comment
	);

	dispatch(setReadingComment(data, globalData));

	return dispatch(
		processEdits(
			[
				{
					name: datFileName,
					key: datFileKey,
					edits: [
						{
							type: EditType.COMMENTS,
							index: gpsReadingIndex,
							reading: { comments }
						}
					]
				}
			],
			data
		)
	);
	// return save({ setData: false, computedIndex })(dispatch, getState);
};

export const updateGlobalData = globalData => (dispatch, getState) => {
	const data = Object.keys(globalData.MasterLST).map(
		key => globalData.MasterLST[key]
	);
	if (data.length === 0) {
		dispatch(setGpsReadingsStillLoading(false));
	} else if (!_dataHasGpsReadings(data)) {
		dispatch(setGpsReadingsStillLoading(true));
	} else {
		dispatch(setGpsReadingsStillLoading(false));
		onReadingsChanged(data)(dispatch, getState);
	}

	dispatch(setGlobalData(globalData, data));
	dispatch(resetSurveyAlignment());

	dispatch(setSurveyType(globalData.surveyType));
	dispatch(setEditableSurvey(globalData));
};

// Turns a reading into a Skip
export const replaceReading = (
	datIndex,
	gpsReadingIndex,
	computedIndex,
	code,
	dats
) => (dispatch, getState) => {
	const isSkip = code === 4;
	let readingUpdate = {};
	if (isSkip) {
		const currentFile = dats[datIndex];
		let prevOrNextReading;

		for (let i = gpsReadingIndex; i >= 0; i -= 1) {
			if (currentFile.gpsreadings[i].code !== 4) {
				prevOrNextReading = currentFile.gpsreadings[i];
				break;
			}
		}
		if (prevOrNextReading) {
			readingUpdate = {
				code: prevOrNextReading.code,
				reading1: prevOrNextReading.reading1,
				reading2: prevOrNextReading.reading2,
				time: prevOrNextReading.time,
				readingReplaced: true
			};
		}
	} else {
		readingUpdate = {
			code: 4,
			reading1: undefined,
			reading2: undefined,
			time: undefined,
			readingReplaced: true
		};
	}

	const {
		data: dataUpdatedReadings,
		globalData: globalDataUpdatedReadings
	} = _getDataFromUpdatingSingleReading(
		getState,
		datIndex,
		gpsReadingIndex,
		readingUpdate
	);

	const datFile = _getDatFileByIndex(getState, datIndex);
	const { data, globalData } = _getNewDataFromChangedDatFileField(
		datFile,
		getState,
		'isDeleted',
		true,
		{
			data: dataUpdatedReadings,
			globalData: globalDataUpdatedReadings
		}
	);
	dispatch(setReplaceReading(data, globalData));
	save({ data, globalData, setData: false, computedIndex })(dispatch, getState);

	AutoCorrectionUtil.analyticsTrackCoordinates(
		'reading-replaced-with-skip',
		{ datFile, globalData },
		{ reading: datFile.gpsreadings[gpsReadingIndex] }
	);
};

export const deleteReading = (datIndex, gpsReadingIndex) => async (
	dispatch,
	getState
) => {
	const state = getState();
	const { cisview } = state;
	const { job } = cisview;
	const { data, globalData } = job;

	const [newData, newGlobalData] = EditReadings.deleteSingleReading(
		globalData,
		data,
		datIndex,
		gpsReadingIndex
	);

	onReadingsChanged(newData)(dispatch, getState);
	dispatch(setDeleteReading(newData, newGlobalData));
	dispatch(setSpikeEditorShouldChange());

	sendDeleteReading(
		dispatch,
		getState,
		newData[datIndex],
		newGlobalData,
		gpsReadingIndex,
		1
	);
};

export const addSkip = (
	datIndex,
	gpsReadingIndex,
	computedIndex,
	numberOfSkips
) => (dispatch, getState) => {
	const state = getState();
	const { cisview } = state;
	const { job } = cisview;
	const { data, globalData } = job;

	const { timeuuid } = globalData;

	const datFile = data[datIndex];

	const {
		fileName,
		endStn: originalEndStn,
		calcEnd: originalCalcEnd,
		interval,
		readingsCount: originalReadingsCount
	} = datFile;
	const endStn = originalEndStn + numberOfSkips * interval;
	const calcEnd = originalCalcEnd + numberOfSkips * interval;
	const readingsCount = originalReadingsCount + numberOfSkips;

	const newDatFile = {
		...datFile,
		gpsreadings: _getNewGpsReadingsFromAddingSkip(
			datFile,
			gpsReadingIndex,
			numberOfSkips
		),
		endStn,
		calcEnd,
		readingsCount,
		isEdited: true
	};

	const newData = data.map((dat, i) => {
		if (i !== datIndex) {
			return dat;
		}

		return newDatFile;
	});

	const newGlobalData = {
		...globalData,
		MasterLST: {
			...globalData.MasterLST,
			[fileName]: newDatFile
		}
	};

	onReadingsChanged(newData, computedIndex)(dispatch, getState);
	dispatch(setAddSkip(newData, newGlobalData));

	const update = {
		job: timeuuid,
		readingsCount,
		calcEnd,
		endStn,
		dat: fileName
	};
	JobUtil.update(update, error => {
		if (error) {
			dispatch(displayDatSaveMessage(error, null, datFile));
		}
	});
	save({ data: newData, globalData: newGlobalData, setData: false })(
		dispatch,
		getState
	);
};

export const updateTrims = (datFile, trims, options) => (
	dispatch,
	getState
) => {
	const { fileName } = datFile;

	incrementDatFileSavingCount()(dispatch);

	// This call actually updates state
	const { data, globalData } = changeDatFileTrims(
		datFile,
		trims,
		options
	)(dispatch, getState);
	return new Promise((res, rej) => {
		JobUtil.merge(trims, globalData.timeuuid, fileName, err => {
			if (err) {
				dispatch(_datFileSavingCompleted(err, null, { fileName }));
				rej(err);
				return;
			}

			dispatch(_datFileSavingCompleted(null, null, { fileName }, options));
			res({ data, globalData });
		});
	});
};

export const addStn = value => async (dispatch, getState) => {
	const state = getState();
	const { cisview } = state;
	const { job } = cisview;
	const { globalData: originalGlobalData, data: originalData } = job;
	const { timeuuid } = originalGlobalData;

	const visibleFileNames = DatFileUtil.getVisibleFiles(originalGlobalData).map(
		f => f.name
	);
	const datFiles = originalData.filter(datFile =>
		visibleFileNames.includes(datFile.fileName)
	);

	dispatch(setAddDatFileCount());

	let globalData = originalGlobalData;
	let data = originalData;

	const allChanges = [];
	for (let i = 0; i < datFiles.length; i += 1) {
		/** * First update start/end stations ** */
		const datFile = datFiles[i];

		const changes = {
			startStn: datFile.startStn + +value,
			endStn:
				(datFile.readingsCount - 1) * datFile.interval +
				(datFile.startStn + +value)
		};
		const serverChanges = {
			...changes,
			name: datFile.fileName
		};

		allChanges.push(serverChanges);

		// For each datFile (with edits), we create a new immutable object
		const {
			globalData: newGlobalData,
			data: newData
		} = _getNewDataFromChangedDatFileFields(datFile, getState, changes, {
			globalData,
			data
		});

		globalData = newGlobalData;
		data = newData;

		/** * Then update trims if necessary ** */
		const { trims = [] } = datFile;
		if (trims.length > 0) {
			const updatedTrims = addStationToTrims(trims, +value);

			// eslint-disable-next-line no-await-in-loop
			const updateTrimsResponse = await updateTrims(datFile, updatedTrims, {
				globalData,
				data,
				showSaveMessage: false
			})(dispatch, getState);

			globalData = updateTrimsResponse.globalData;
			data = updateTrimsResponse.data;
		}
	}

	dispatch(setGlobalData(globalData, data));

	JobUtil.addStn({ job: timeuuid, files: allChanges }, err => {
		if (err) {
			// rej({ err, files });
			dispatch(_datFileSavingCompleted(err, null, { files: allChanges }));
		} else {
			// res({ files });
			dispatch(_datFileSavingCompleted(null, null, { files: allChanges }));
		}
	});
};

export const deleteSkip = (
	datIndex,
	readingIndex,
	computedIndex,
	numberOfSkips
) => async (dispatch, getState) => {
	const state = getState();
	const { cisview } = state;
	const { job } = cisview;
	const { data, globalData } = job;

	const [newData, newGlobalData] = EditReadings.deleteSequentialReadings(
		globalData,
		data,
		datIndex,
		numberOfSkips,
		readingIndex
	);

	onReadingsChanged(newData, computedIndex)(dispatch, getState);
	dispatch(setDeleteSkip(newData, newGlobalData));

	sendDeleteReading(
		dispatch,
		getState,
		newData[datIndex],
		newGlobalData,
		readingIndex,
		numberOfSkips
	);
};

// Makes call to the server and reverts if there is an error
const _callUpdateJob = (serverChanges, fileName) => (dispatch, getState) => {
	const { globalData: oldGlobalData, data: oldData } = _getCurrentData(
		getState
	);
	incrementDatFileSavingCount()(dispatch);
	return new Promise((res, rej) => {
		JobUtil.update(serverChanges, err => {
			if (err) {
				rej(err);
				// Revert data when there is an error
				dispatch(setGlobalData(oldGlobalData, oldData));
				dispatch(_datFileSavingCompleted(err, null, { fileName }));
			} else {
				res();
				dispatch(_datFileSavingCompleted(null, null, { fileName }));
			}
		});
	});
};

const _updateJobBySingleFieldChange = (datFile, fieldName, fieldValue) => (
	dispatch,
	getState
) => {
	const { fileName } = datFile;
	// In case we need to reverse (when there is an error)

	const { data, globalData } = _getNewDataFromChangedDatFileFields(
		datFile,
		getState,
		{ [fieldName]: fieldValue }
	);

	const serverChanges = {
		attribute: fieldName,
		value: fieldValue,
		dat: datFile.fileName,
		fileKey: datFile.fileKey,
		job: selectJobId(getState())
	};
	dispatch(setGlobalData(globalData, data));

	return _callUpdateJob(serverChanges, fileName)(dispatch, getState);
};

export const _updateJobByPropertyChanges = (
	datFile,
	propertyChanges,
	serverPropertyChanges
) => (dispatch, getState) => {
	const { fileName } = datFile;
	// In case we need to reverse (when there is an error)

	const { data, globalData } = _getNewDataFromChangedDatFileFields(
		datFile,
		getState,
		propertyChanges
	);

	const serverChanges = {
		...(serverPropertyChanges || propertyChanges),
		dat: datFile.fileName,
		fileKey: datFile.fileKey,
		job: selectJobId(getState())
	};
	dispatch(setGlobalData(globalData, data));

	// Returns a promise resolved with data/globalData
	const promise = _callUpdateJob(serverChanges, fileName)(
		dispatch,
		getState
	).then(() => ({ data, globalData }));

	onReadingsChanged(data)(dispatch, getState);

	return promise;
};

export const saveHeaderInfo = (datFile, fieldName, fieldValue) => (
	dispatch,
	getState
) => {
	return _updateJobBySingleFieldChange(
		datFile,
		fieldName,
		fieldValue
	)(dispatch, getState);

	// _updateJobWithPropertyChange(datFile, propertyChanges)(dispatch, getState);
};

export const saveStartStation = (datFile, value) => (dispatch, getState) => {
	const startStn = Number(value);
	const { interval } = datFile;
	const endStn = startStn + interval * (datFile.readingsCount - 1);
	const propertyChanges = {
		startStn,
		endStn
	};
	const serverChanges = {
		// This is what the server needs
		startStn,
		endStn
	};

	return _updateJobByPropertyChanges(
		datFile,
		propertyChanges,
		serverChanges
	)(dispatch, getState);
};

export const saveStationAlignment = () => async (dispatch, getState) => {
	const state = getState();
	const { cisview } = state;
	const { job, stationAlignment, readings } = cisview;

	const { convertedReadingsByFile } = readings;

	const { globalData: originalGlobalData, data: originalData } = job;

	const { realignedComputedReadings } = stationAlignment;

	const { newData, newGlobalData } = getReadingsWithAlignedStationIds(
		originalData,
		originalGlobalData,
		realignedComputedReadings,
		convertedReadingsByFile
	);

	dispatch(setGlobalData(newGlobalData, newData));
	onReadingsChanged(newData)(dispatch, getState);

	await _saveJobFromStationAligments(newGlobalData, newData, dispatch);

	return save({
		data: newData,
		globalData: newGlobalData,
		setData: false
	})(dispatch, getState);
};

export const _updateJobWithFlips = (datFiles, userDefinedStartStation) => (
	dispatch,
	getState
) => {
	return new Promise((res, rej) => {
		const state = getState();
		const { cisview } = state;
		const { job } = cisview;
		const { globalData: originalGlobalData, data: originalData } = job;
		const { timeuuid } = originalGlobalData;

		let globalData = originalGlobalData;
		let data = originalData;

		const allChanges = [];
		incrementDatFileSavingCount()(dispatch);
		const datFilesSortedWithNewStations = _getNewStationsForFlips(
			datFiles,
			userDefinedStartStation
		);

		Object.keys(globalData.MasterLST).forEach(fileName => {
			// We need to get the original order to send to the api -> thus the double reverse
			const datFile = datFilesSortedWithNewStations.filter(
				d => d.fileName === fileName
			)[0];

			// This particular file obviously was not included in the flip
			if (!datFile) {
				return;
			}
			const { startStn, endStn } = datFile;

			const changes = {
				reverse: !datFile.reverse,
				startStn,
				endStn
			};
			const serverChanges = {
				reverse: !datFile.reverse,
				name: fileName
			};

			allChanges.push(serverChanges);

			// For each datFile (with edits), we create a new immutable object
			const {
				globalData: newGlobalData,
				data: newData
			} = _getNewDataFromChangedDatFileFields(datFile, getState, changes, {
				globalData,
				data
			});

			globalData = newGlobalData;
			data = newData;
		});

		data = _sortDatFilesByStartStn(data);
		globalData = _resortGlobalData(globalData, data);

		onReadingsChanged(data)(dispatch, getState);

		dispatch(setGlobalData(globalData, data));
		JobUtil.flip({ job: timeuuid, files: allChanges }, err => {
			if (err) {
				rej(err);

				dispatch(setGlobalData(originalGlobalData, originalData));
				dispatch(_datFileSavingCompleted(err, null, { files: allChanges }));
			} else {
				res();
				// Only update global data if a success

				// If the user provided a start Station, then we need to do more working before finishing
				if (!userDefinedStartStation) {
					dispatch(_datFileSavingCompleted(null, null, { files: allChanges }));
				}
			}
		});
	});
};

export const flipDatFiles = (datFiles, startStnFromUser) => (
	dispatch,
	getState
) => {
	const state = getState();
	const { cisview } = state;
	const { job } = cisview;
	const { data: allData } = job;

	const dataToFlip = datFiles || allData;

	const startStn = _getNewStartStationForReverse(startStnFromUser, dataToFlip);

	return _updateJobWithFlips(dataToFlip, startStn)(dispatch, getState);
};

export const flipDatFile = datFile => (dispatch, getState) => {
	const startStn = _getStartStationFromUser(datFile);
	return flipDatFiles([datFile], startStn)(dispatch, getState).then(() => {
		if (startStn !== 0) {
			saveStartStation(datFile, startStn)(dispatch, getState).then(() => {
				dispatch(decrementDatFileSavingCount());
			});
		}
	});
};

export const saveInterval = (datFile, value) => (dispatch, getState) => {
	const { startStn: start } = datFile;
	const interval = +value;
	const endStn = start + interval * (datFile.readingsCount - 1);
	const propertyChanges = {
		interval,
		endStn,
		gpsreadings: _getNewGpsReadingsFromChangingInterval(datFile, interval)
	};
	const serverChanges = {
		interval,
		endStn
	};

	// This will actually update data/globalData in store
	return _updateJobByPropertyChanges(
		datFile,
		propertyChanges,
		serverChanges
	)(dispatch, getState).then(({ data }) => {
		onReadingsChanged(data)(dispatch, getState);

		// No need to pass data/globalData - they are already in state
		save({ setData: false })(dispatch, getState);
	});
};

export const savePipeDiameter = (datFile, value) => (dispatch, getState) => {
	const pipeDiameter = +value;
	const propertyChanges = {
		pipeDiameter
	};
	const serverChanges = {
		pipeDiameter
	};

	return _updateJobByPropertyChanges(
		datFile,
		propertyChanges,
		serverChanges
	)(dispatch, getState).then(({ data }) => {
		onReadingsChanged(data)(dispatch, getState);
		save({ setData: false })(dispatch, getState);
	});
};

export const saveSegmentGaps = smoothedPoints => (dispatch, getState) => {
	const state = getState();
	const { cisview } = state;
	const { job } = cisview;
	const { globalData, data } = job;

	const {
		newData,
		newGlobalData
	} = MapProcessingUtil.updateGpsReadingsWithSegmentSmoothedPoints(
		smoothedPoints,
		globalData,
		data
	);

	dispatch(setGlobalData(newGlobalData, newData));

	const promise = save({
		data: newData,
		globalData: newGlobalData,
		setData: false
	})(dispatch, getState).catch(() => {
		dispatch(setGlobalData(globalData, data));
	});

	onReadingsChanged(newData)(dispatch, getState);
	return promise;
};

export const moveToSegment = segment => (dispatch, getState) => {
	_updateJobWithSingleDatFileChange(
		'segment',
		segment,
		JobUtil.moveToSegment.bind(JobUtil)
	)(dispatch, getState);
};

export const changeFileStatus = fileStatus => (dispatch, getState) => {
	const promise = _updateJobWithSingleDatFileChange(
		'fileStatus',
		fileStatus,
		JobUtil.changeFileStatus.bind(JobUtil),
		{ eventName: 'file-status-change' }
	)(dispatch, getState);

	return promise.then(() => {
		_additionalStatusChanges(fileStatus)(dispatch, getState);
	});
};

export const changeSync = (sync, onComplete = () => {}) => (
	dispatch,
	getState
) => {
	const state = getState();
	const { cisview } = state;
	const { job } = cisview;
	const { globalData, data } = job;

	const update = {
		job: globalData.timeuuid,
		sync
	};

	JobUtil.update(update, err => {
		if (err) {
			dispatch(displayDatSaveMessage(err, null));
			onComplete();
		} else {
			const newGlobalData = {
				...globalData,
				sync
			};
			dispatch(setGlobalData(newGlobalData, data));
			onComplete();
		}
	});
};

export const changeCisvStnAlign = cisvStnAlign => (dispatch, getState) => {
	const state = getState();
	const { cisview } = state;
	const { job } = cisview;
	const { globalData, data } = job;

	const update = {
		job: globalData.timeuuid,
		cisvStnAlign
	};

	JobUtil.update(update, err => {
		if (err) {
			dispatch(displayDatSaveMessage(err, null));
		} else {
			const newGlobalData = {
				...globalData,
				cisvStnAlign
			};
			dispatch(setGlobalData(newGlobalData, data));
		}
	});
};

export const setHistoricalJobs = () => (dispatch, getState) => {
	const { cisview } = getState();
	const { job } = cisview;
	const { globalData } = job;
	JobUtil.getHistoricalJobs(
		globalData.job,
		globalData.lineName,
		globalData.customer,
		(err, historicalJobs) => {
			if (err) {
				console.log('err');
			} else {
				dispatch(acSetHistoricalJobs(historicalJobs));
			}
		}
	);
};

export const changeStatus = (jobguid, status, statusFromSurvey) => (
	dispatch,
	getState
) => {
	const { cisview } = getState();
	const { job } = cisview;
	const { globalData, data } = job;

	dispatch(setGlobalJobStatus(status));
	incrementDatFileSavingCount()(dispatch);

	if (status === COMPLETE && statusFromSurvey) {
		if (globalData && globalData.MasterLST) {
			Object.keys(globalData.MasterLST).forEach(() => {
				_updateJobWithSingleDatFileChange(
					'fileStatus',
					COMPLETE,
					JobUtil.changeFileStatus.bind(JobUtil)
				)(dispatch, getState);
			});
		}
	}
	JobUtil.changeStatus(jobguid, status, err => {
		if (err) {
			dispatch(_datFileSavingCompleted(err));
			return;
		}
		dispatch(
			_datFileSavingCompleted(null, 'Survey Status Updated Succesfully')
		);

		_sendSegmentEventForJobStatusChange(globalData, status, data);
	});
};

export const changeAssistingDP = (jobguid, assistingDP) => dispatch => {
	dispatch(setGlobalAssistingDP(assistingDP));
	incrementDatFileSavingCount()(dispatch);

	JobUtil.changeAssistingDP(jobguid, assistingDP, err => {
		if (err) {
			dispatch(_datFileSavingCompleted(err));
			return;
		}
		dispatch(
			_datFileSavingCompleted(
				null,
				'Survey Assisting Data Processor Updated Succesfully'
			)
		);
	});
};
