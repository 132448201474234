/* eslint-disable import/prefer-default-export */
import { isDate } from 'aegion_common_utilities/lib/DateUtil';
import { MM_DD_YYYY } from 'aegion_common_utilities/lib/format/datetimeless';
import { GROUP_TYPES } from '../constants';
import { formatMillivolt, formatStrMillivolt } from '../../../../formatNumber';

const isDefined = v => v !== undefined && v !== null;

const computeDepolParts = (r, { useInterpolatedDepol, useNormalizedDepol }) => {
	if (r) {
		if (useInterpolatedDepol && useNormalizedDepol) {
			if (r.isNiDepolValNormalized && r.isNiDepolValInterpolated) {
				return ['(n)(i)', r.niDepolVal];
			}
			if (r.isNiDepolValNormalized) {
				return ['(n)', r.niDepolVal];
			}
			if (r.isNiDepolValInterpolated) {
				return ['(i)', r.niDepolVal];
			}
			return [undefined, r.niDepolVal];
		}

		if (useInterpolatedDepol) {
			if (r.isInterpolated) {
				return ['(i)', r.iDepolVal];
			}
			return [undefined, r.iDepolVal];
		}

		if (useNormalizedDepol) {
			if (r.isNormalized) {
				return ['(n)', r.nDepolVal];
			}
			return [undefined, r.nDepolVal];
		}

		if (!useInterpolatedDepol && !useNormalizedDepol) {
			return [undefined, r.depol];
		}
	}
	return [];
};

export const createGetColumns = survey => {
	const filterCompositeDepol = cols =>
		cols.filter(c => c.label !== 'DEPOL' && c.label !== 'SHADOW');

	const allColumns = [
		{
			label: 'ID',
			value: 'formattedStationId',
			checked: true,
			width: 100,
			required: true,
			groups: [GROUP_TYPES.DEFAULT, GROUP_TYPES.SAWTOOTH]
		},
		{
			label: 'ON',
			value: 'on',
			checked: true,
			width: 44,
			groups: [GROUP_TYPES.DEFAULT],
			getValue: r => (!!r && formatMillivolt(r.on)) || undefined,
			showInTooltip: true
		},
		{
			label: 'OFF',
			value: 'off',
			checked: true,
			width: 44,
			groups: [GROUP_TYPES.DEFAULT],
			getValue: r => (!!r && formatMillivolt(r.off)) || undefined,
			showInTooltip: true
		},
		{
			label: 'SAWTOOTH',
			value: 'sawtooth',
			checked: true,
			width: 88,
			groups: [GROUP_TYPES.SAWTOOTH],
			getValue: r => (!!r && formatMillivolt(r.sawtooth)) || undefined,
			showInTooltip: true
		},
		{
			label: 'DEPOL',
			value: 'depol',
			checked: !!survey.withDepol,
			width: 80,
			groups: [GROUP_TYPES.DEFAULT, GROUP_TYPES.SAWTOOTH],
			getValue: (r, { useInterpolatedDepol, useNormalizedDepol } = {}) => {
				const [prefix, val] = computeDepolParts(r, {
					useInterpolatedDepol,
					useNormalizedDepol
				});
				return [prefix, formatStrMillivolt(val)].filter(v => !!v).join(' ');
			},
			getCsvValue: (r, { useInterpolatedDepol, useNormalizedDepol } = {}) => {
				const [, val] = computeDepolParts(r, {
					useInterpolatedDepol,
					useNormalizedDepol
				});
				if (val) {
					return formatStrMillivolt(val);
				}
				return undefined;
			},
			showInTooltip: true
		},
		{
			label: 'SHADOW',
			value: 'sh',
			checked: !!survey.withDepol,
			width: 80,
			groups: [GROUP_TYPES.DEFAULT, GROUP_TYPES.SAWTOOTH],
			getValue: (r, { useInterpolatedDepol, useNormalizedDepol } = {}) => {
				const [prefix, val] = computeDepolParts(r, {
					useInterpolatedDepol,
					useNormalizedDepol
				});
				if (val) {
					return [prefix, formatStrMillivolt(val - 100)]
						.filter(v => !!v)
						.join(' ');
				}
				return undefined;
			},
			getCsvValue: (r, { useInterpolatedDepol, useNormalizedDepol } = {}) => {
				const [, val] = computeDepolParts(r, {
					useInterpolatedDepol,
					useNormalizedDepol
				});
				if (val) {
					return formatStrMillivolt(val - 100);
				}
				return undefined;
			},
			showInTooltip: true
		},
		{
			label: 'DISTANCE',
			value: 'dst',
			checked: true,
			width: 80,
			groups: [GROUP_TYPES.DEFAULT, GROUP_TYPES.SAWTOOTH]
		},
		{
			label: 'LAT',
			value: 'lat',
			checked: true,
			width: 65,
			groups: [GROUP_TYPES.DEFAULT, GROUP_TYPES.SAWTOOTH],
			getCsvValue: r => {
				if (isDefined(r.lat)) {
					return r.lat;
				}
				if (isDefined(r.loc)) {
					return r.loc[0];
				}
				return undefined;
			}
		},
		{
			label: 'LNG',
			value: 'lng',
			checked: true,
			width: 70,
			groups: [GROUP_TYPES.DEFAULT, GROUP_TYPES.SAWTOOTH],
			getCsvValue: r => {
				if (isDefined(r.lng)) {
					return r.lng;
				}
				if (isDefined(r.loc)) {
					return r.loc[1];
				}
				return undefined;
			}
		},
		{
			label: 'DATE',
			value: 'date',
			checked: false,
			width: 120,
			groups: [GROUP_TYPES.DEFAULT, GROUP_TYPES.SAWTOOTH],
			getValue: r => (isDate(r.date) ? MM_DD_YYYY(r.date) : '')
		},
		{
			label: 'COMMENTS',
			value: 'cmt',
			checked: true,
			width: 255,
			groups: [GROUP_TYPES.DEFAULT, GROUP_TYPES.SAWTOOTH],
			type: 'text'
		}
	];
	const allColumnsSansDepol = filterCompositeDepol(allColumns);

	const defaultColumns = allColumns.filter(
		c => c.groups.indexOf(GROUP_TYPES.DEFAULT) > -1
	);
	const defaultColumnsSansDepol = filterCompositeDepol(defaultColumns);

	const sawtoothColumns = allColumns.filter(
		c => c.groups.indexOf(GROUP_TYPES.SAWTOOTH) > -1
	);
	const sawtoothColumnsSansDepol = filterCompositeDepol(sawtoothColumns);

	const getColumns = ({ useSawtooth, all, hasCompositeDepol } = {}) => {
		if (all) {
			return hasCompositeDepol === false ? allColumnsSansDepol : allColumns;
		}
		if (useSawtooth) {
			return hasCompositeDepol === false
				? sawtoothColumnsSansDepol
				: sawtoothColumns;
		}
		return hasCompositeDepol === false
			? defaultColumnsSansDepol
			: defaultColumns;
	};

	return getColumns;
};
