import React, { Suspense, lazy } from 'react';
import { connect } from 'react-redux';

import { Route, Switch } from 'react-router-dom';
import Loading from '../../../cisview/oldWebShared/aip/loading';
import { rootPath as assetViewPath } from '../../../assetView/redux/routing/util/routepath';
import { identify } from '../../../util/analytics/segment';
import {
	getCustomer,
	getUserName,
	getUserUUID,
	getEmail,
	hasCognitoGroupName
} from '../../../util/user';
import { AWS_GROUP_NAMES } from '../../../util/permissions/constants';

import {
	AppAccount,
	DevAppSelect,
	AppScanline,
	AppCISView,
	AppFieldLine,
	DataIntegrity,
	AppChargeLine,
	AppAssetView,
	AppLiveLine,
	AppVideoLine,
	AppTemplateBuilder,
	AppSettings,
	DashboardsAndReports,
	WebsiteBridge,
	Applications
} from './LazyLoading';
import LeftPanel from '../LeftPanel';

const Admin = lazy(() =>
	import(/* webpackChunkName: "Admin" */ '../../../admin')
);

const MainRouter = () => {
	identify(getUserUUID(), {
		customer: getCustomer(),
		username: getUserName(),
		email: getEmail()
	});
	return (
		<>
			<Suspense fallback={<Loading title="Loading..." />}>
				<Switch>
					<Route path="/Account" component={AppAccount} />
					<Route path="/devappselect" component={DevAppSelect} />
					<Route path="/Scanline" component={AppScanline} />
					<Route path="/CISLine" component={AppCISView} />
					<Route path="/FieldLine" component={AppFieldLine} />
					<Route path="/FieldLineReact" component={AppFieldLine} />
					<Route path="/DataIntegrity" component={DataIntegrity} />
					<Route path="/ChargeLine" component={AppChargeLine} />
					<Route path={assetViewPath} component={AppAssetView} />
					<Route path="/LiveLine" component={AppLiveLine} />
					<Route path="/VideoLine" component={AppVideoLine} />
					<Route path="/templateBuilder" component={AppTemplateBuilder} />
					<Route path="/CustomerUsers" component={AppSettings} />
					<Route path="/AppList" component={Applications} />
					<Route
						path="/dashboards-and-reports"
						component={DashboardsAndReports}
					/>

					{(hasCognitoGroupName(AWS_GROUP_NAMES.AIP_ADMINS) ||
						hasCognitoGroupName(AWS_GROUP_NAMES.CUSTOMER_ADMINS)) && (
						<Route path="/admin" component={Admin} />
					)}

					<Route path="/Index" component={WebsiteBridge} />
					<Route exact path="/" component={WebsiteBridge} />
					{/* <Route path="/websiteExperiments" component={WebsiteExperimental} /> */}
					{window.hideReactApplcations === undefined && (
						<Route path="/" component={Applications} />
					)}
				</Switch>
			</Suspense>
			<Switch>
				<Route path="/Index" component={null} />
				<Route exact path="/" component={null} />
				<Route path="" component={LeftPanel} />
			</Switch>
		</>
	);
};

export default connect()(MainRouter);
