import { createSelector } from 'reselect';
import { selectSliceCisview } from './HELPERS';
import { ALIGNMENT_READING_TABLE_TYPES } from '../actions/util/station-alignment';
import TAB_IDS from '../reducers/utils/tabIds';

const selectStationAlignmentType = (state, { stationAlignmentType }) => {
	return stationAlignmentType;
};

export const selectTabMode = createSelector(
	selectSliceCisview,
	({
		tabs: {
			mode: { id }
		}
	}) => id
);

const selectIsStationAlignment = createSelector(
	selectTabMode,
	tabId => TAB_IDS.STATION_ALIGNMENT === tabId
);

const selectShouldUseAdditionalSurveyData = createSelector(
	[selectIsStationAlignment, selectStationAlignmentType],
	(isStationAlignment, stationAlignmentType) => {
		if (!isStationAlignment) {
			return false;
		}
		if (stationAlignmentType !== ALIGNMENT_READING_TABLE_TYPES.staticType) {
			return false;
		}

		return true;
	}
);

const selectJob = createSelector(selectSliceCisview, ({ job }) => job);

export const selectData = createSelector(selectJob, ({ data }) => data);

const selectGlobalData = createSelector(
	selectJob,
	({ globalData }) => globalData
);

export const selectGlobalDataSurveyType = createSelector(
	selectGlobalData,
	({ surveyType }) => surveyType
);

// eslint-disable-next-line import/prefer-default-export
export const selectSurveyType = createSelector(
	[selectGlobalDataSurveyType, selectData, selectShouldUseAdditionalSurveyData],
	(surveyType, data, shouldUseAdditionalSurveyData) => {
		if (!shouldUseAdditionalSurveyData) {
			return surveyType;
		}
		if (!data || data.length === 0) {
			return null;
		}

		return data[0].surveyType;
	}
);

const selectReadings = createSelector(
	selectSliceCisview,
	({ readings }) => readings
);

export const selectConvertedReadings = createSelector(
	selectReadings,
	({ convertedReadings }) => convertedReadings
);

export const selectUnsortedConvertedReadings = createSelector(
	selectReadings,
	({ unsortedConvertedReadings }) => unsortedConvertedReadings
);

const selectSkipReadings = createSelector(
	selectReadings,
	({ skipReadings }) => skipReadings
);

const selectAdditionalSurveys = createSelector(
	selectSliceCisview,
	({ additionalSurveys }) => additionalSurveys
);

export const selectAdditionalSurveysDatFilesByTimeuuid = createSelector(
	selectAdditionalSurveys,
	({ datFilesByTimeuuid }) => datFilesByTimeuuid
);

const selectFilterForReadingsTab = createSelector(
	selectReadings,
	({ filter }) => filter
);

const selectFiltersByTimeuuid = createSelector(
	selectAdditionalSurveys,
	({ filtersByTimeuuid }) => filtersByTimeuuid
);

export const selectConvertedReadingsByTimeuuid = createSelector(
	selectAdditionalSurveys,
	({ convertedReadingsByTimeuuid }) => convertedReadingsByTimeuuid
);

const selectDatTimeuuid = (state, { dat: { timeuuid } }) => timeuuid;

const selectFilterValue = createSelector(
	[
		selectFilterForReadingsTab,
		selectFiltersByTimeuuid,
		selectIsStationAlignment,
		selectDatTimeuuid
	],
	(filterForReadingsTab, filtersByTimeuuid, isStationAligment, timeuuid) => {
		if (!isStationAligment) {
			return filterForReadingsTab || '';
		}
		const filterOptions = filtersByTimeuuid[timeuuid];
		if (!filterOptions) {
			return '';
		}

		return filterOptions.filterText || '';
	}
);

const selectFilteredStationAlignmentReadings = createSelector(
	[selectFiltersByTimeuuid, selectDatTimeuuid],
	(filtersByTimeuuid, timeuuid) => {
		const filterOptions = filtersByTimeuuid[timeuuid] || {};
		const { filteredReadings } = filterOptions;

		return filteredReadings;
	}
);

const selectConvertedReadingsAdditonalSurveys = createSelector(
	[selectConvertedReadingsByTimeuuid, selectDatTimeuuid],
	(convertedReadingsByTimeuuid, timeuuid) => {
		return convertedReadingsByTimeuuid[timeuuid];
	}
);

export const selectStationAlignmentReadings = createSelector(
	[
		selectIsStationAlignment,
		selectFilterValue,
		selectFilteredStationAlignmentReadings,
		selectConvertedReadingsAdditonalSurveys
	],
	(
		isStationAlignment,
		filterValue,
		filteredStationAlignmentReadings,
		convertedReadings
	) => {
		if (!isStationAlignment) {
			return [];
		}
		if (filterValue) {
			return filteredStationAlignmentReadings;
		}

		return convertedReadings;
	}
);

export const selectTabReadings = createSelector(
	[
		selectStationAlignmentReadings,
		selectConvertedReadings,
		selectSkipReadings,
		selectTabMode
	],
	(stationAlignmentReadings, convertedReadings, skipReadings, tabMod) => {
		switch (tabMod) {
			case 'readings':
				return convertedReadings;
			case 'skips':
				return skipReadings;
			case 'stationAlignment':
				return stationAlignmentReadings;
			default:
				return null;
		}
	}
);
