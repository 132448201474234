"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
exports.findColumnSettings = findColumnSettings;
exports.getFields = exports.getColumns = exports.getColumnLengths = exports.getColumnLabels = exports.findEmptyColumns = void 0;
exports.getLabel = getLabel;
exports.valueAccessor = exports.getStructureSettings = exports.getSourceField = exports.getRowFields = void 0;

var _lodash = _interopRequireDefault(require("lodash.union"));

var _lodash2 = _interopRequireDefault(require("lodash.get"));

var _constants = require("../constants");

var _strings = require("../../format/strings");

var _excluded = ["attr"];

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest();
}

function _iterableToArrayLimit(arr, i) {
  var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"];

  if (_i == null) return;
  var _arr = [];
  var _n = true;
  var _d = false;

  var _s, _e;

  try {
    for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _toArray(arr) {
  return _arrayWithHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableRest();
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _toConsumableArray(arr) {
  return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread();
}

function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}

function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === "string") return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor) n = o.constructor.name;
  if (n === "Map" || n === "Set") return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}

function _iterableToArray(iter) {
  if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter);
}

function _arrayWithoutHoles(arr) {
  if (Array.isArray(arr)) return _arrayLikeToArray(arr);
}

function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;

  for (var i = 0, arr2 = new Array(len); i < len; i++) {
    arr2[i] = arr[i];
  }

  return arr2;
}

var onOffRegex = /^(.*)((Off)|(On))$/;

function cleanName(name) {
  if (onOffRegex.test(name)) {
    var groups = onOffRegex.exec(name);
    return {
      name: groups[1],
      type: onOffRegex.exec(name)[2]
    };
  }

  return {
    name: name
  };
}

function getLabel(name, labels) {
  var shouldReturnDefault = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;

  var _cleanName = cleanName(name),
      cleanedName = _cleanName.name,
      type = _cleanName.type;

  var _labels$cleanedName = labels[cleanedName],
      label = _labels$cleanedName === void 0 ? {} : _labels$cleanedName;
  var _label$Label = label.Label,
      Label = _label$Label === void 0 ? shouldReturnDefault ? (0, _strings.toCapitalCase)(name) : undefined : _label$Label;

  if (type && Label !== undefined) {
    return "".concat(Label, " ").concat(type);
  }

  return Label;
}

var getSourceField = function getSourceField(approvalMode) {
  var sourceFields = ['_source.item', '_source.preapproval.item'];

  if (approvalMode === _constants.APPROVAL_MODE.approved) {
    return sourceFields[0];
  }

  if (approvalMode === _constants.APPROVAL_MODE.unapproved) {
    return sourceFields[1];
  }

  return sourceFields;
};

exports.getSourceField = getSourceField;

function getLabels(row) {
  var _row$_source$labels = row._source.labels,
      labels = _row$_source$labels === void 0 ? {} : _row$_source$labels;
  return labels;
}

function getLength(value) {
  if (value === undefined) {
    return 0;
  }

  if (value === null) {
    return 0;
  }

  if (value === 'not set') {
    return 0;
  }

  return value.toString().length;
}

var getRowFields = function getRowFields(row, approvalMode) {
  var sourceField = getSourceField(approvalMode);
  var item = (0, _lodash2["default"])(row, sourceField) || {};
  var labelsInfo = getLabels(row);
  return Object.keys(item).map(function (attr) {
    var label = getLabel(attr, labelsInfo, false);
    var labels = [];

    if (label) {
      labels.push(label);
    }

    var src = "".concat(sourceField, ".").concat(attr);
    var value = (0, _lodash2["default"])(row, src);
    var maxLength = getLength(value);
    return {
      attr: attr,
      labels: labels,
      src: [src],
      maxLength: maxLength
    };
  });
};

exports.getRowFields = getRowFields;

function getMaxLength(len1, len2) {
  if (len1 > len2) {
    return len1;
  }

  return len2;
}

var getFields = function getFields(hits, approvalMode) {
  return hits.reduce(function (acc, row) {
    var rowFields = getRowFields(row, approvalMode);
    var missingFields = rowFields.filter(function (_ref) {
      var attr = _ref.attr;
      return !acc.some(function (_ref2) {
        var a = _ref2.attr;
        return a === attr;
      });
    });
    var existingFields = rowFields.filter(function (_ref3) {
      var attr = _ref3.attr;
      return acc.some(function (_ref4) {
        var a = _ref4.attr;
        return a !== attr;
      });
    }); // console.log(existingFields);

    return [].concat(_toConsumableArray(acc.map(function (field) {
      var labels = field.labels,
          maxLength = field.maxLength;
      var existingField = existingFields.find(function (f) {
        return field.attr === f.attr;
      });

      if (!existingField) {
        return field;
      }

      return _objectSpread(_objectSpread({}, field), {}, {
        labels: (0, _lodash["default"])(labels, existingField.labels),
        maxLength: getMaxLength(maxLength, existingField.maxLength)
      });
    })), _toConsumableArray(missingFields));
  }, []);
};

exports.getFields = getFields;

function getRegEx(column) {
  var name = column.name,
      regEx = column.regEx;
  var regex = new RegExp("^".concat(regEx || name.replace('_source.item.', ''), "$"));
  return regex;
}

function getMatch(column, field) {
  if (column === undefined) {
    return undefined;
  }

  var regex = getRegEx(column);
  var match = regex.exec(field);
  return match;
}

function findColumn(settings, field) {
  return settings.find(function (column) {
    if (Array.isArray(column)) {
      return findColumn(column, field);
    }

    var match = getMatch(column, field);

    if (match !== null) {
      return field;
    }

    return undefined;
  });
}

function applyMatch(str, match) {
  return Object.keys(match.groups).reduce(function (acc, group) {
    var regEx = new RegExp("\\${".concat(group, "}"));
    return acc.replace(regEx, match.groups[group]);
  }, str);
}

function cleanColumn(field, column, match) {
  if (!column) {
    return column;
  }

  var xlsTitle = column.xlsTitle,
      corTitle = column.corTitle;

  if (/\${.*}/.test(xlsTitle) || /\${.*}/.test(corTitle)) {
    return _objectSpread(_objectSpread({}, column), {}, {
      xlsTitle: applyMatch(xlsTitle, match),
      corTitle: applyMatch(corTitle, match)
    });
  }

  return column;
}

function findColumnSettings(settings, field) {
  var column = findColumn(settings, field);
  var index = settings.indexOf(column);

  if (Array.isArray(column)) {
    var colSettings = findColumnSettings(settings[index], field);
    return _objectSpread(_objectSpread({}, colSettings), {}, {
      index: [{
        index: index,
        len: settings.length
      }].concat(_toConsumableArray(colSettings.index))
    });
  }

  var match = getMatch(column, field); // const { corTitle , xlsTitle} = column;
  // console.log(corTitle , xlsTitle, match);

  return {
    index: [{
      index: index,
      len: settings.length
    }],
    column: cleanColumn(field, column, match),
    match: match
  };
}

function checkNegativeOne(value) {
  if (value === -1) {
    return 1000;
  }

  return value;
}

function getFieldsSettings(hitFields, columnSettings) {
  var mapping = hitFields.map(function (_ref5) {
    var attr = _ref5.attr,
        rest = _objectWithoutProperties(_ref5, _excluded);

    var settings = findColumnSettings(columnSettings, attr);
    return _objectSpread(_objectSpread({
      field: attr
    }, rest), settings);
  });
  return mapping;
} // only works for 2 deep


function getSortNumber(field, index) {
  var _index2 = _toArray(index),
      idx = _index2[0],
      rest = _index2.slice(1);

  var num = checkNegativeOne(idx.index);

  if (rest.length > 0) {
    var _rest = _slicedToArray(rest, 1),
        subIdx = _rest[0];

    var match = field.match;
    var _match$groups = match.groups;
    _match$groups = _match$groups === void 0 ? {} : _match$groups;
    var _match$groups$num = _match$groups.num,
        groupNum = _match$groups$num === void 0 ? 0 : _match$groups$num;
    var subSortNum = subIdx.len * Number(groupNum) + subIdx.index;
    return num + subSortNum * 0.0001;
  }

  return num;
}

function getCombinedFieldsSettings(fieldsSettings) {
  return fieldsSettings.reduce(function (acc, fieldSettings) {
    var _fieldSettings$column = fieldSettings.column;
    _fieldSettings$column = _fieldSettings$column === void 0 ? {} : _fieldSettings$column;
    var regEx = _fieldSettings$column.regEx,
        _fieldSettings$match = fieldSettings.match;
    _fieldSettings$match = _fieldSettings$match === void 0 ? {} : _fieldSettings$match;
    var _fieldSettings$match$ = _fieldSettings$match.groups;
    _fieldSettings$match$ = _fieldSettings$match$ === void 0 ? {} : _fieldSettings$match$;
    var _fieldSettings$match$2 = _fieldSettings$match$.num,
        number = _fieldSettings$match$2 === void 0 ? 0 : _fieldSettings$match$2;
    var existingSettings = acc.find(function (_ref6) {
      var _ref6$column = _ref6.column;
      _ref6$column = _ref6$column === void 0 ? {} : _ref6$column;
      var regex = _ref6$column.regEx,
          _ref6$match = _ref6.match;
      _ref6$match = _ref6$match === void 0 ? {} : _ref6$match;
      var _ref6$match$groups = _ref6$match.groups;
      _ref6$match$groups = _ref6$match$groups === void 0 ? {} : _ref6$match$groups;
      var _ref6$match$groups$nu = _ref6$match$groups.num,
          num = _ref6$match$groups$nu === void 0 ? 0 : _ref6$match$groups$nu;

      if (!regex) {
        return false;
      }

      return regex === regEx && num === number;
    });

    if (!existingSettings) {
      return [].concat(_toConsumableArray(acc), [_objectSpread(_objectSpread({}, fieldSettings), {}, {
        fields: [fieldSettings.field]
      })]);
    }

    var _existingSettings$fie = existingSettings.fields,
        existingFields = _existingSettings$fie === void 0 ? [] : _existingSettings$fie,
        _existingSettings$lab = existingSettings.labels,
        existingLabels = _existingSettings$lab === void 0 ? [] : _existingSettings$lab,
        _existingSettings$src = existingSettings.src,
        existingSrc = _existingSettings$src === void 0 ? [] : _existingSettings$src,
        existingMaxLength = existingSettings.maxLength;
    return [].concat(_toConsumableArray(acc.slice(0, acc.length - 1)), [_objectSpread(_objectSpread({}, existingSettings), {}, {
      labels: (0, _lodash["default"])(existingLabels, fieldSettings.labels),
      src: (0, _lodash["default"])(existingSrc, fieldSettings.src),
      fields: [].concat(_toConsumableArray(existingFields), [fieldSettings.field]),
      maxLength: getMaxLength(existingMaxLength, fieldSettings.maxLength)
    })]);
  }, []);
}

var getColumnLabel = function getColumnLabel(column) {
  var _column$column = column.column;
  _column$column = _column$column === void 0 ? {} : _column$column;
  var xlsTitle = _column$column.xlsTitle,
      field = column.field,
      labels = column.labels;
  var capitalCase = (0, _strings.toCapitalCase)(field.substring(field.lastIndexOf('.') + 1));

  if (labels && labels.length > 0) {
    return labels.join('/');
  }

  if (xlsTitle) {
    return xlsTitle;
  }

  return capitalCase;
};

var getColumns = function getColumns(hits, managerSettings, approvalMode) {
  // console.time('getColumns');
  var hitFields = getFields(hits, approvalMode);

  var _ref7 = managerSettings || {},
      _ref7$columns = _ref7.columns,
      columnSettings = _ref7$columns === void 0 ? [] : _ref7$columns;

  var fieldsSettings = getFieldsSettings(hitFields, columnSettings);
  fieldsSettings.sort(function (field1, field2) {
    var num1 = getSortNumber(field1, field1.index);
    var num2 = getSortNumber(field2, field2.index);

    if (num1 === 1000 && num2 === 1000) {
      var label1 = getColumnLabel(field1);
      var label2 = getColumnLabel(field2);

      if (label1 < label2) {
        return -1;
      }

      if (label1 > label2) {
        return +1;
      }
    }

    return num1 - num2;
  });
  var combinedFieldsSettings = getCombinedFieldsSettings(fieldsSettings);
  var columnsWithLabel = combinedFieldsSettings.map(function (column) {
    return _objectSpread(_objectSpread({}, column), {}, {
      label: getColumnLabel(column)
    });
  }); // console.timeEnd('getColumns');

  return columnsWithLabel;
};

exports.getColumns = getColumns;

var findEmptyColumns = function findEmptyColumns(hits, managerSettings, approvalMode) {
  var columns = getColumns(hits, managerSettings, approvalMode);
  var emptyFields = columns.filter(function (column) {
    return !hits.some(function (row) {
      return column.src.some(function (s) {
        var value = (0, _lodash2["default"])(row, s);
        return value !== undefined && value !== null && value !== 'not set' && value !== '';
      });
    });
  });
  return emptyFields;
};

exports.findEmptyColumns = findEmptyColumns;

var getColumnLabels = function getColumnLabels(hits, managerSettings, approvalMode) {
  var columns = getColumns(hits, managerSettings, approvalMode);
  var columnLabels = columns.map(function (_ref8) {
    var field = _ref8.field,
        xlsTitle = _ref8.xlsTitle,
        labels = _ref8.labels;
    return {
      field: field,
      xlsTitle: xlsTitle,
      capitalCase: (0, _strings.toCapitalCase)(field),
      labels: labels
    };
  });
  return columnLabels;
};

exports.getColumnLabels = getColumnLabels;

var getColumnLengths = function getColumnLengths(hits, managerSettings, approvalMode) {
  var columns = getColumns(hits, managerSettings, approvalMode);
  var columnLengths = columns.map(function (_ref9) {
    var field = _ref9.field,
        maxLength = _ref9.maxLength; // console.log(name);

    return {
      name: field,
      maxLength: maxLength
    };
  });
  return columnLengths;
};

exports.getColumnLengths = getColumnLengths;

function isHistory(originalRow) {
  var _index = originalRow._index;

  if (_index === undefined) {
    return true;
  }

  if (_index.indexOf('histor') > -1) {
    return true;
  }

  return false;
}

function cleanSource(src) {
  var history = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

  if (src === undefined) {
    return src;
  }

  if (history) {
    return src.replace('.preapproval', '');
  }

  return src;
}

function getSourceName(original, sources) {
  var column = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var _column$column2 = column.column;
  _column$column2 = _column$column2 === void 0 ? {} : _column$column2;
  var regEx = _column$column2.regEx,
      _column$match = column.match;
  _column$match = _column$match === void 0 ? {} : _column$match;
  var _column$match$groups = _column$match.groups;
  _column$match$groups = _column$match$groups === void 0 ? {} : _column$match$groups;
  var num = _column$match$groups.num;
  var history = isHistory(original);
  var src = sources.find(function (s) {
    return (0, _lodash2["default"])(original, cleanSource(s, history)) !== undefined;
  });

  if (src === undefined) {
    if (history && regEx && num) {
      var hitFields = getRowFields(original, _constants.APPROVAL_MODE.approved);
      var field = hitFields.find(function (_ref10) {
        var attr = _ref10.attr;
        var newMatch = getMatch({
          regEx: regEx
        }, attr);

        if (newMatch === null) {
          return false;
        }

        var _newMatch$groups = newMatch.groups;
        _newMatch$groups = _newMatch$groups === void 0 ? {} : _newMatch$groups;
        var newNum = _newMatch$groups.num;
        return newNum === num;
      });

      if (field && field.src) {
        return getSourceName(original, field.src);
      }
    }

    return src;
  }

  if (history) {
    return src.replace('.preapproval', '');
  }

  return src;
}

var valueAccessor = function valueAccessor(originalRow, sources, columnIndx, column) {
  var _originalRow$_source = originalRow._source;
  _originalRow$_source = _originalRow$_source === void 0 ? {} : _originalRow$_source;
  var cCode = _originalRow$_source.classCode;

  if (isHistory(originalRow)) {
    if (cCode === _constants.DOWNLOADING_TEXT) {
      if (columnIndx === 0) {
        return _constants.DOWNLOADING_TEXT;
      }

      return '';
    }

    var _src = getSourceName(originalRow, sources, column);

    if (_src === undefined) {
      return '';
    }

    return (0, _lodash2["default"])(originalRow, _src);
  }

  var src = getSourceName(originalRow, sources);

  if (src === undefined) {
    return '';
  }

  var value = (0, _lodash2["default"])(originalRow, src);
  return value;
};

exports.valueAccessor = valueAccessor;

var getStructureSettings = function getStructureSettings(managerSettings, name) {
  var _managerSettings$filt = managerSettings.filter(function (_ref11) {
    var _name = _ref11.name;
    return _name === name;
  }),
      _managerSettings$filt2 = _slicedToArray(_managerSettings$filt, 1),
      structureSettings = _managerSettings$filt2[0];

  if (!structureSettings && name !== 'default') {
    return getStructureSettings(managerSettings, 'default');
  }

  return structureSettings;
};

exports.getStructureSettings = getStructureSettings;
var _default = {
  getSourceField: getSourceField,
  getRowFields: getRowFields,
  getFields: getFields,
  findColumnSettings: findColumnSettings,
  getColumns: getColumns,
  findEmptyColumns: findEmptyColumns,
  getColumnLabels: getColumnLabels,
  getColumnLengths: getColumnLengths,
  valueAccessor: valueAccessor,
  getStructureSettings: getStructureSettings,
  getLabel: getLabel
};
exports["default"] = _default;