import aipurls, { URL } from './configs.urls';

const getAipUrls = () => {
	return aipurls;
};

function getURL(path) {
	return `${URL}/${path}`;
}

export default getAipUrls;

export { getURL };
