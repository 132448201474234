/* eslint-disable import/no-cycle */
import { handleActions, combineActions } from 'redux-actions';

import {
	flResetJobsMap,
	flRequestJobs,
	flWindowResize,
	flUpdateJobMetadata,
	flReceiveJobs,
	flChangePage,
	flReceiveLines,
	flReceiveFilters,
	flSetFilter
} from '../actions/jobs';
import {
	flSelectCustomer,
	flInvalidateCustomer,
	flReceiveCustomers
} from '../actions/customers';

const jobsInitialState = {
	availableCustomers: [],
	availableFilters: [],
	availableJobs: [],
	currentJob: {},
	filteredJobs: [],
	isLoadingJobs: false,
	filters: [],
	lines: [],
	height: window.innerHeight,
	currentPage: 1,
	jobMetadata: null,
	visibleJobs: [],
	currentCustomer: {},
	isLoadingCustomers: false
};

const jobsReducer = handleActions(
	{
		[combineActions(flSelectCustomer, flInvalidateCustomer, flRequestJobs)](
			state,
			{ payload: { customer } }
		) {
			return {
				...state,
				currentCustomer: customer,
				isLoadingJobs: true,
				availableJobs: []
			};
		},

		// this is bad to reset map
		[flResetJobsMap]: state => {
			return {
				...state,
				lines: []
			};
		},

		[flWindowResize]: (state, { payload: { height } }) => {
			return {
				...state,
				height
			};
		},

		[flUpdateJobMetadata]: (state, { payload: { metadata } }) => {
			return {
				...state,
				jobMetadata: metadata
			};
		},
		[flReceiveCustomers]: (state, { payload: { customers } }) => {
			return {
				...state,
				availableCustomers: customers,
				currentCustomer: customers[0]
			};
		},
		[flReceiveJobs]: (state, { payload: { jobs } }) => {
			const filters = [];
			if (jobs.length > 0) {
				const keys = Object.keys(jobs[0]);
				jobs.forEach(j => {
					keys.forEach(key => {
						if (key !== 'jobid') {
							filters.push(`${key === 'id' ? 'aegion_job_id' : key}:${j[key]}`);
						}
					});
				});
			}
			const filtersSet = new Set();

			filters.forEach(item => {
				filtersSet.add(item);
			});
			const availableFilters = [];
			filtersSet.forEach(item => {
				availableFilters.push(item);
			});
			return {
				...state,
				availableJobs: jobs,
				availableFilters,
				filters: [],
				isLoadingJobs: false
			};
		},
		[flReceiveLines]: (state, { payload: { lines } }) => {
			return { ...state, lines: [...state.lines, ...lines] };
		},

		[flChangePage]: (state, { payload: { page } }) => {
			return { ...state, currentPage: page };
		},

		[flReceiveFilters]: (
			state,
			{ payload: { filters, filteredJobs, visibleJobs } }
		) => {
			return {
				...state,
				filters,
				filteredJobs,
				visibleJobs
			};
		},
		[flSetFilter]: (state, { payload: { filter } }) => {
			return {
				...state,
				filters: [filter]
			};
		}
	},
	jobsInitialState
);

export default jobsReducer;
