const DEFAULT_EMAILS = ['wbarnes@aegion.com', 'sarora@aegion.com'];

const EMAIL_MAP = {
	AIM: ['wbarnes@aegion.com', 'sarora@aegion.com'],
	CISVIEW: ['sarora@aegion.com', 'wbarnes@aegion.com'],
	DIT: ['wbarnes@aegion.com', 'sarora@aegion.com'],
	SCANLINE: ['wbarnes@aegion.com', 'sarora@aegion.com']
};

export const getInAppChatToEmails = (appName = '') => {
	const key = appName.toUpperCase();
	return EMAIL_MAP[key] || DEFAULT_EMAILS;
};

export default {
	getInAppChatToEmails
};
