import React from 'react';
import { isTokenValid } from '../../../util/user';

const LoginButton = () => {
	if (isTokenValid()) {
		return null;
	}
	return (
		<li>
			<a style={{ paddingTop: '12px' }} href="/Account/Login">
				LOGIN
			</a>
		</li>
	);
};

export default LoginButton;
