const TAB_IDS = {
	UPLOADS: 'uploads',
	DOWNLOADS: 'downloads',
	SURVEY_FILES: 'surveyFiles',
	READINGS: 'readings',
	OVERLAPS: 'overlaps',
	SPIKE_EDITOR: 'spikeEditor',
	STATION_ALIGNMENT: 'stationAlignment',
	SKIPS: 'skips',
	SEGMENT_GAP_ANALYSIS: 'segmentGapAnalysis',
	QAQC: 'qaqc'
};

export const BETA_TESTING_TABS = [TAB_IDS.STATION_ALIGNMENT];

export default TAB_IDS;
