import { MiscUtils } from 'aegion_common_utilities';

// eslint-disable-next-line import/no-cycle
import GpsAutoCorrectionUtil from './GpsAutoCorrectionUtil';
import ReadingsAutoCorrectionUtil from './ReadingsAutoCorrectionUtil';

// eslint-disable-next-line import/no-cycle
import {
	readingsAutoCorrectionActions,
	gpsAutoCorrectionActions,
	tabActions
} from '../../redux/actions';

// eslint-disable-next-line import/no-cycle
import { store } from '../../../scanline/store';

import { canUseSpikeEditorMagicButton } from '../../config/betaTesting';
import { track } from '../../../util/analytics/segment';
import { getUserName, getUserUUID } from '../../../util/user';

const AutoCorrectionUtil = {
	hastStartedTimeTracking: false,
	abTestingInfoCoordinates: {},
	abTestingInfoReadings: {},
	LEDGER_UPDATE_ACTIONS: {
		ADD: 'add',
		DELETE: 'delete'
	},
	getRandomModelToUse() {
		const flipOfTheCoin = Math.random() >= 0.5;

		const modelToUse = flipOfTheCoin ? 'A' : 'B';

		return modelToUse;
	},
	// Ignore the desciption when tracking an event on segment
	getAbTestingIdInfo(abTesting) {
		if (!abTesting) {
			return {};
		}

		if (Object.keys(abTesting).length === 0) {
			return {};
		}

		// eslint-disable-next-line camelcase
		const { ab_choice, model_id } = abTesting;
		return {
			abTestingInfo: {
				ab_choice,
				model_id
			}
		};
	},
	setAbCoordinates(abInfo) {
		this.abTestingInfoCoordinates = abInfo;
	},
	setAbReadings(abInfo) {
		this.abTestingInfoReadings = abInfo;
	},
	analyticsTrackReadings(eventName, data, additionalEventItems = {}) {
		this.analyticsTrack('readings', eventName, data, {
			...additionalEventItems,
			...this.getAbTestingIdInfo(this.abTestingInfoReadings)
		});
	},
	analyticsTrackCoordinates(eventName, data, additionalEventItems = {}) {
		this.analyticsTrack('coordinates', eventName, data, {
			...additionalEventItems,
			abTestingInfo: this.getAbTestingIdInfo(this.abTestingInfoCoordinates)
		});
	},
	analyticsTrackMisc(eventName, data, additionalEventItems) {
		this.analyticsTrack(null, eventName, data, additionalEventItems);
	},

	getCurrentTab() {
		const state = store.getState();
		if (!(state || {}).cisview) {
			return undefined;
		}

		const { tabs } = state.cisview;
		if (!tabs) {
			return undefined;
		}

		const { mode } = tabs;

		if (!mode) {
			return undefined;
		}

		return mode.id;
	},

	getDatFileInfoToTrack(datFile) {
		const {
			fileName,
			interval,
			reverse,
			fileKey,
			startStn,
			endStn,
			trims
		} = datFile;

		return { fileName, interval, reverse, fileKey, startStn, endStn, trims };
	},
	extendTrackInfoWithDatFiles(data, events) {
		const { datFile, datFiles: rawDatFiles } = data;

		let datFiles = [];
		if (datFile) {
			datFiles.push(datFile);
		}
		if (rawDatFiles) {
			datFiles = [...datFiles, ...rawDatFiles];
		}

		return {
			...events,
			datFiles: datFiles.map(this.getDatFileInfoToTrack)
		};
	},
	getCisVersion() {
		const versions = [
			'1.0.0', // Before flattening readings

			// After flattening readings. Change log:
			// - record when file status change
			// - record when reading becomes a skip
			// - pass in setByAllFileStatusComplete to job status change
			'1.0.1'
		];

		return versions[versions.length - 1];
	},
	analyticsTrack(type, eventName, data, additionalEventItems = {}) {
		let events = {
			analyticsType: type,
			cisVersion: this.getCisVersion(),
			...additionalEventItems
		};

		const currentTab = AutoCorrectionUtil.getCurrentTab();

		if (currentTab !== undefined) {
			events.currentTab = currentTab;
		}

		events = this.extendTrackInfoWithDatFiles(data, events);

		if (data && data.globalData) {
			const {
				MasterLST,
				timeuuid,
				assignToPM,
				customer,
				entityName,
				lineName,
				projectManager,
				surveyType
			} = data.globalData;

			events = {
				...events,
				globalInfo: {
					MasterLST: MasterLST && Object.keys(MasterLST),
					timeuuid,
					assignToPM,
					customer,
					entityName,
					lineName,
					projectManager,
					surveyType
				},
				user: getUserUUID(),
				userName: getUserName()
			};
		}

		track(eventName, events);
	},

	calculateTimeSpentOnAllPreviousTabs() {
		const newPreviousTabsList = tabActions.appendToPreviousTabsList(
			store.getState
		)[0];

		return newPreviousTabsList;
	},

	sendTimeOnJobEvent(timeStartPageView, eventName, timeuuid, events) {
		const timeOnPage = new Date() - timeStartPageView;
		const timeOnPageInSeconds = parseInt(timeOnPage / 1000, 10);
		const timeOnPageInMinutes = MiscUtils.roundToDigitPlace(
			timeOnPageInSeconds / 60,
			2
		);

		const timeSpentOnEachTab = this.calculateTimeSpentOnAllPreviousTabs();

		track(eventName, {
			timeOnPage,
			timeOnPageInSeconds,
			timeOnPageInMinutes,
			jobguid: timeuuid,
			userName: events.userName,
			timeSpentOnEachTab
		});
	},

	analyticsTrackTimeOnAJob(eventName, data, additionalEventItems = {}) {
		if (!data || !data.globalData) {
			return;
		}

		if (this.hastStartedTimeTracking) {
			return;
		}

		this.hastStartedTimeTracking = true;
		let events = {
			...additionalEventItems
		};
		events = {
			...events,
			user: getUserUUID(),
			userName: getUserName()
		};
		const timeStartPageView = new Date();
		const { timeuuid } = data.globalData;

		window.addEventListener('beforeunload', () => {
			this.sendTimeOnJobEvent(timeStartPageView, eventName, timeuuid, events);
		});
	},

	canUseAutoCorrect() {
		const confirmationText =
			'You are about to automatically align GPS points. This will take some time to complete (up to one minute and sometimes more). Also, please note that other edit tools will be disabled until auto process is complete.\n\nPlease note that a new toolbar will be available to save the autosmoothing or erase changes completely. You can interact with the changes within the map by clicking on a smoothed point (while holding ctrl key) or by dragging a rectangle around multiple points (while holding ctrl key). You can easily undo any changes by hitting ctrl+z on your keyboard.\n\nAre you sure you want to continue?';

		// TODO: using a javascript confirm box - this is a temporary hack
		// eslint-disable-next-line no-alert
		return !MiscUtils.isProd() || window.confirm(confirmationText);
	},

	isUserValidatedForSpikeEditorMagicButton() {},

	isUserAllowedToUseSpikeEditorMagicButton() {
		if (canUseSpikeEditorMagicButton()) {
			return true;
		}

		// ?magic=true exists in query string
		if (this.urlMagicTokenExists()) {
			return true;
		}

		return false;
	},

	urlMagicTokenExists() {
		try {
			// https://davidwalsh.name/query-string-javascript
			const urlParams = new URLSearchParams(window.location.search);
			const magicToken = urlParams.get('magic');
			if (magicToken && Boolean(magicToken)) {
				return true;
			}
		} catch (e) {
			return false;
		}
		return false;
	},

	mergeReadingsWithFlagsAndUserChanges(
		datFile,
		readingsLedgersByFile,
		spikeEditorUserChangesByFile
	) {
		return ReadingsAutoCorrectionUtil.mergeReadingsWithFlagsAndUserChanges(
			datFile,
			readingsLedgersByFile,
			spikeEditorUserChangesByFile
		);
	},

	// TODO: I don't think this is used -> delete
	revertReadingsSpikes(autoCorrectedReadingsData, onIndexes, offIndexes) {
		return ReadingsAutoCorrectionUtil.revertSpikes(
			autoCorrectedReadingsData,
			onIndexes,
			offIndexes
		);
	},

	mergeCoordinatesWithFlags(originalDatFile, coordinateLedger, userActions) {
		return GpsAutoCorrectionUtil.mergeCoordinatesWithFlags(
			originalDatFile,
			coordinateLedger,
			userActions
		);
	},

	setGpsReadingsMoved(originalGpsReadings, coordinateLedger) {
		return GpsAutoCorrectionUtil.setGpsReadingsMoved(
			originalGpsReadings,
			coordinateLedger
		);
	},

	performAutoCorrectionActionsFromStack(
		originalDatFile,
		gpsReadings,
		_userInteractions
	) {
		GpsAutoCorrectionUtil.performAutoCorrectionActionsFromStack(
			originalDatFile,
			gpsReadings,
			_userInteractions
		);
	},

	updatedLedgerBasedOnUpdate(readingIndexes, datFile, addOrDelete) {
		const state = store.getState();
		const { dispatch } = store;

		const { gpsAutoCorrection, readingsAutoCorrection } = state.cisview;
		const { autoCorrectionsByFileName } = gpsAutoCorrection;
		const { readingsLedgersByFile } = readingsAutoCorrection;

		const { updatedReadingLedgerBasedOnUpdate } = readingsAutoCorrectionActions;
		const { updateCoordinateLedgerByFileName } = gpsAutoCorrectionActions;

		const { fileName, gpsreadings } = datFile;

		const currentGpsAutoCorrection = autoCorrectionsByFileName[fileName] || {};

		const { coordinateLedger } = currentGpsAutoCorrection;

		const gpsreadingsLength = gpsreadings.length;
		const readingsLedger = readingsLedgersByFile[fileName];

		const { readings1: readings1Ledger, readings2: readings2Ledger } =
			readingsLedger || {};

		const {
			newReadings1Ledger,
			newReadings2Ledger,
			newCoordinateLedger
		} = AutoCorrectionUtil.getNewLedgerBasedOnAnyUpdate(
			readingIndexes,
			gpsreadingsLength,
			readings1Ledger,
			readings2Ledger,
			coordinateLedger,
			addOrDelete
		);

		if (newReadings1Ledger && newReadings2Ledger) {
			dispatch(
				updatedReadingLedgerBasedOnUpdate({
					datFile,
					newReadings1Ledger,
					newReadings2Ledger
				})
			);
		}

		if (newCoordinateLedger) {
			dispatch(updateCoordinateLedgerByFileName(fileName, newCoordinateLedger));
		}
	},

	updateLedgerFromReadingDeleted(deletedReadingIndex, datFile) {
		const { DELETE } = AutoCorrectionUtil.LEDGER_UPDATE_ACTIONS;
		this.updatedLedgerBasedOnUpdate([deletedReadingIndex], datFile, DELETE);
	},

	updateLedgerFromReadingAdded(addedReadingIndex, datFile) {
		const { ADD } = AutoCorrectionUtil.LEDGER_UPDATE_ACTIONS;
		this.updatedLedgerBasedOnUpdate([addedReadingIndex], datFile, ADD);
	},

	getLedgerUpdateFunction(addOrDelete) {
		const { ADD, DELETE } = this.LEDGER_UPDATE_ACTIONS;

		switch (addOrDelete) {
			case ADD:
				return this.getNewLedgerIndexesBasedOnAddedReading;
			case DELETE:
				return this.getNewLedgerIndexesBasedOnDeletedReading;

			default:
				throw new Error(
					`Unknown "addOrDelete" option "${addOrDelete}" for function updatedLedgerBasedOnUpdate`
				);
		}
	},

	getNewLedgerBasedOnAnyUpdate(
		readingIndexes,
		gpsreadingsLength,
		readings1Ledger,
		readings2Ledger,
		coordinateLedger,
		addOrDelete
	) {
		const ledgerUpdateFunction = this.getLedgerUpdateFunction(addOrDelete);

		let newReadings1Ledger;
		let newReadings2Ledger;
		if (readings1Ledger && readings2Ledger) {
			newReadings1Ledger = ledgerUpdateFunction(readings1Ledger, {
				readingIndexes,
				gpsreadingsLength
			});
			newReadings2Ledger = ledgerUpdateFunction(readings2Ledger, {
				readingIndexes,
				gpsreadingsLength
			});
		}

		let newCoordinateLedger;
		if (coordinateLedger) {
			newCoordinateLedger = ledgerUpdateFunction(coordinateLedger, {
				readingIndexes,
				gpsreadingsLength
			});
		}

		return { newReadings1Ledger, newReadings2Ledger, newCoordinateLedger };
	},

	getNewLedgerIndexesBasedOnFlippedGpsReading(ledger, { gpsreadingsLength }) {
		// Flip based on the gpsReadingsLength
		return ledger.map(index => {
			return gpsreadingsLength - index - 1;
		});
	},

	getNewLedgerIndexesBasedOnAddedReading(ledger, { readingIndexes }) {
		const sortedReadingIndexesCopy = readingIndexes
			.slice()
			.sort((a, b) => a - b);
		let pointsToAdd = 0;
		return ledger.reduce((goodReadingIndexes, currentReadingIndex) => {
			if (currentReadingIndex >= sortedReadingIndexesCopy[0]) {
				pointsToAdd += 1;
				// Remove first element
				sortedReadingIndexesCopy.splice(0, 1);
			}

			const newIndex = currentReadingIndex + pointsToAdd;

			return goodReadingIndexes.concat(newIndex);
		}, []);
	},

	getNewLedgerIndexesBasedOnDeletedReading(ledger, { readingIndexes }) {
		const sortedReadingIndexesCopy = readingIndexes
			.slice()
			.sort((a, b) => a - b);
		let pointsToDelete = 0;
		return ledger.reduce((goodReadingIndexes, currentReadingIndex) => {
			if (currentReadingIndex === sortedReadingIndexesCopy[0]) {
				pointsToDelete += 1;
				sortedReadingIndexesCopy.splice(0, 1);
				return goodReadingIndexes; // we want to delete the ledgerIndex matching the reading index that was deleted
			}

			if (currentReadingIndex > sortedReadingIndexesCopy[0]) {
				pointsToDelete += 1;
				sortedReadingIndexesCopy.splice(0, 1);
			}
			const newIndex = currentReadingIndex - pointsToDelete; // If the index value is less than the value deleted, then we don't need to do anything

			return goodReadingIndexes.concat(newIndex);
		}, []);
	},
	prepareSaveAutoCorrectionReadings(
		datFile,
		readingsLedgersByFile,
		spikeEditorUserChangesByFile
	) {
		return ReadingsAutoCorrectionUtil.prepareSaveAutoCorrectionReadings(
			datFile,
			readingsLedgersByFile,
			spikeEditorUserChangesByFile
		);
	},

	getAutoCorrectionWithinShapeByFile(
		boundsOfRectangle,
		autoCorrectionsByFileName
	) {
		return GpsAutoCorrectionUtil.getAutoCorrectionWithinShapeByFile(
			boundsOfRectangle,
			autoCorrectionsByFileName
		);
	},

	reverseSelectedPointsIfNecessary(rawOnIndexes, rawOffIndexes) {
		const state = store.getState();

		const { spikeEditorSpikeGraph } = state.cisview;
		const { allTraces, selectedDatFile } = spikeEditorSpikeGraph;

		return ReadingsAutoCorrectionUtil.reverse.reverseSelectedPointsIfNecessary(
			rawOnIndexes,
			rawOffIndexes,
			selectedDatFile,
			allTraces
		);
	}
};
export default AutoCorrectionUtil;
