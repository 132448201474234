import { MiscUtils } from 'aegion_common_utilities/';
import { getUserName } from '../../util/user';

import {
	SPIKE_EDITOR_MAGIC_WAND_BETA_TESTERS,
	STATION_ALIGNMENT_BETA_TESTERS
} from './userSettings';

export const isSpikeEditorMagicButtonBetaTester = () => {
	return SPIKE_EDITOR_MAGIC_WAND_BETA_TESTERS.includes(getUserName());
};

const isStationAlignmentBetaTester = () => {
	return STATION_ALIGNMENT_BETA_TESTERS.includes(getUserName());
};

export const canUseSpikeEditorMagicButton = () => {
	if (!MiscUtils.isProd()) {
		return true;
	}

	// Users who can use the magic button in prod
	return isSpikeEditorMagicButtonBetaTester();
};

export const canUseStationAlignmentTool = () => {
	if (!MiscUtils.isProd()) {
		return true;
	}

	return isStationAlignmentBetaTester();
};
