export function determineIfAutoCorrectionIsInProcess(
	selectedDatFile,
	readingsLedgersByFile
) {
	if (!selectedDatFile || !selectedDatFile.fileName) {
		return false;
	}

	if (!readingsLedgersByFile) {
		return false;
	}

	const ledger = readingsLedgersByFile[selectedDatFile.fileName];

	if (!ledger) {
		return false;
	}

	return true;
}

// deprecated
function isDataInMV(readings) {
	return true;
	/* if (!readings.length) {
		return false; // Cannot determine from information
	}

	const readingsWithValues = readings.filter(reading => reading);

	const itemsAbove100 = readingsWithValues.filter(
		reading => Math.abs(reading) > 100
	);

	const ratioAbove100 = itemsAbove100.length / readingsWithValues.length;

	// if more than half of the items are above 100, then we are definitely dealing with MV
	if (ratioAbove100 > 0.5) {
		return true;
	}

  return false; 
  */
}

function getAutoCorrectedDatFile(
	selectedDatFile,
	autoCorrectedReadingsDataByFile
) {
	if (!selectedDatFile || !autoCorrectedReadingsDataByFile) {
		return null;
	}

	return autoCorrectedReadingsDataByFile[selectedDatFile.fileName];
}

// Will change onOffData in place to represent
export function changeVoltage(onOffData, mv) {
	const onReadings = onOffData.map(obj => obj.on);

	const isCurrentDataMv = true; // isDataInMV(onReadings);

	if (isCurrentDataMv && mv) {
		// nothing to do here
		return;
	}
	if (!isCurrentDataMv && !mv) {
		// nothing to do here
		return;
	}

	let multiplier;
	if (isCurrentDataMv && !mv) {
		multiplier = 0.001; // We want MV to become Volts so we divide by 1000
	}
	if (!isCurrentDataMv && mv) {
		multiplier = 1000; // We want Volts to become MV so we multiply by 1000
	}

	// Perform transformation (inline)
	for (let i = 0; i < onOffData.length; i += 1) {
		const obj = onOffData[i];
		if (obj.on) {
			obj.on *= multiplier;
		}
		if (obj.off) {
			obj.off *= multiplier;
		}
	}
}

// Will change onOffData in place to represent
function getDefaultMv(/* onOffData, mv */) {
	return true;
	/* const onReadings = onOffData.map(obj => obj.on);

	const isCurrentDataMv = isDataInMV(onReadings);

	if (isCurrentDataMv && mv) {
		// nothing to do here
		return true;
	}
	if (!isCurrentDataMv && !mv) {
		// nothing to do here
		return false;
  }
   /*

	/* let multiplier;
	if (isCurrentDataMv && !mv) {
		multiplier = 0.001; // We want MV to become Volts so we divide by 1000
	}
	if (!isCurrentDataMv && mv) {
		multiplier = 1000; // We want Volts to become MV so we multiply by 1000
	}

	// Perform transformation (inline)
	for (let i = 0; i < onOffData.length; i += 1) {
		const obj = onOffData[i];
		if (obj.on) {
			obj.on *= multiplier;
		}
		if (obj.off) {
			obj.off *= multiplier;
		}
	} */
	/* if (isCurrentDataMv && !mv) {
		return true;
	}
	if (!isCurrentDataMv && mv) {
		return false;
	}
  return mv; 
  */
}

function shouldIncludeReading(oldReading, newReading) {
	if (!oldReading) {
		// If we are just looking at the newReadings then we always want to show
		return true;
	}

	// if there are oldReadings but not newReadings -> Don't show... newReadings will be created from oldReadings later on
	if (!newReading) {
		return false;
	}

	// Only show oldReadings if they are different from new readings
	const reading1Changed = oldReading.reading1 !== newReading.reading1;
	if (reading1Changed) {
		return true;
	}

	const reading2Changed = oldReading.reading2 !== newReading.reading2;
	if (reading2Changed) {
		return true;
	}

	return false;
}

function getGpsReadings(data) {
	if (!data) {
		return null;
	}
	if (Object.prototype.hasOwnProperty.call(data, 'gpsReadingsSets')) {
		const { gpsReadingsSets } = data;
		return gpsReadingsSets[0];
	}
	if (Object.prototype.hasOwnProperty.call(data, 'gpsreadings')) {
		return data.gpsreadings;
	}
	return data;
}

function getReading(gpsReadings, gpsReadingIndex) {
	if (!gpsReadings) {
		return null;
	}

	if (!Array.isArray(gpsReadings)) {
		return null;
	}

	const gpsReading = gpsReadings[gpsReadingIndex];

	if (!gpsReading || !Array.isArray(gpsReading)) {
		return null;
	}

	return gpsReading;
}

// Makes a copy of the data - immutable
function createReadingObject(reading) {
	const obj = {};
	obj.name = 'Dat File'; // Hard code for now
	obj.filekey = '1111'; // Hard code for now
	obj.comment = reading.comments;
	obj.cmtFlag = false;
	if (obj.comment !== undefined && obj.comment !== '') {
		obj.cmtFlag = true;
	}
	obj.stationId = reading.StationId;
	// if (reading.reading1 !== 0) {
	obj.on = reading.reading1;
	// }
	// if (reading.reading2 !== 0) {
	obj.off = reading.reading2;
	// }

	return obj;
}

function recalculateStationId(index, datFile) {
	const { startStn, interval } = datFile;

	return index * interval + startStn;
}

function mutateReadingWithNewStationId(reading, index, datFile) {
	reading.originalStationId = reading.stationId;
	reading.stationId = recalculateStationId(index, datFile);
}

function mutateReadingWithDerivedStationid(reading, datFile) {
	const { startStn, endStn, reverse } = datFile;

	const { stationId: originalStationId } = reading;

	reading.rawStationId = originalStationId;
	if (!reverse) {
		reading.stationId = startStn + originalStationId;
		return;
	}

	reading.stationId = endStn - originalStationId;
}

function mutateReadingsWithDerivedStationid(readings, datFile) {
	readings.forEach(reading =>
		mutateReadingWithDerivedStationid(reading, datFile)
	);

	return readings;
}

// Mutates the array and object
function recalculateStationIds(readings, datFile, useOriginalStation = false) {
	const { reverse } = datFile;

	if (useOriginalStation) {
		mutateReadingsWithDerivedStationid(readings, datFile);
		return readings;
	}

	if (reverse) {
		readings.reverse();
	}

	readings.forEach((reading, i) =>
		mutateReadingWithNewStationId(reading, i, datFile)
	);

	return readings;
}

function getOnOffReadingsFromData(oldData, newData, mv = false) {
	let data;
	const oldGpsReadings = getGpsReadings(oldData);
	const newGpsReadings = getGpsReadings(newData);
	// There are no changes in the data, so do not show any "old" data
	if (oldGpsReadings && !newGpsReadings) {
		return { readings: null, mv };
	}

	// See which has values (default to old data as if this was really for new readings there would not be old data)
	if (
		oldGpsReadings &&
		Array.isArray(oldGpsReadings) &&
		oldGpsReadings.length > 0
	) {
		data = oldData;
	} else if (
		newGpsReadings &&
		Array.isArray(newGpsReadings) &&
		newGpsReadings.length > 0
	) {
		data = newData;
	}

	if (!data) {
		return { readings: [], mv };
	}

	const onOffData = [];
	const gpsReadings = getGpsReadings(data);

	if (!gpsReadings) {
		return { readings: [], mv };
	}

	for (let i = 0; i < gpsReadings.length; i += 1) {
		const gpsReading = gpsReadings[i];
		const oldReading = getReading(oldGpsReadings, i);
		const newReading = getReading(newGpsReadings, i);
		if (shouldIncludeReading(oldReading, newReading)) {
			const obj = createReadingObject(gpsReading);
			onOffData.push(obj);
		}
	}

	const newMv = getDefaultMv(onOffData, mv);
	return { readings: onOffData, mv: newMv };
}

function getPreprocessedReadings(
	preprocessedGPSReadings,
	selectedDatFile,
	useMv = false
) {
	const {
		readings: onOffReadings /* , mv: defaultMv */
	} = getOnOffReadingsFromData(preprocessedGPSReadings, selectedDatFile, useMv);

	return recalculateStationIds(onOffReadings, selectedDatFile, true);
}

function getNewOnOffReadings(
	selectedAutoCorrectedReadingData,
	selectedDatFile,
	useMv
) {
	const {
		readings: onOffReadings,
		mv: defaultOnOffMv
	} = getOnOffReadingsFromData(
		null,
		selectedAutoCorrectedReadingData || [],
		useMv
	);

	// First try with auto correction
	if (onOffReadings && onOffReadings.length > 0) {
		return {
			newData: recalculateStationIds(onOffReadings, selectedDatFile),
			defaultMv: defaultOnOffMv
		};
	}

	// Then try without auto correction
	const { readings, mv: defaultMv } = getOnOffReadingsFromData(
		null,
		selectedDatFile || [],
		useMv
	);
	return {
		newData: recalculateStationIds(readings, selectedDatFile),
		defaultMv
	};
}

function getOldOnOffReadings(
	convertedReadingsForSelectedFile,
	selectedAutoCorrectedReadingData,
	mv,
	selectedDatFile
) {
	// Data has not changed yet, don't show old data
	if (!selectedAutoCorrectedReadingData) {
		return null;
	}

	const onOffData = convertedReadingsForSelectedFile.map(createReadingObject);

	// const defaultMv = getDefaultMv(onOffData, mv);

	// No need to reverse as this is derived from computedReadings - but we still need to change stationIds
	const { startStn, interval } = selectedDatFile;
	return recalculateStationIds(onOffData, {
		startStn,
		interval,
		reverse: false
	});
}

export function getChartDataFromDatFile(
	selectedDatFile,
	convertedReadingsForSelectedFile,
	autoCorrectedReadingsDataByFile,
	useMv
) {
	const selectedAutoCorrectedReadingData = getAutoCorrectedDatFile(
		selectedDatFile,
		autoCorrectedReadingsDataByFile
	);

	const { preprocessedGPSReadings } = selectedDatFile;

	const { newData, defaultMv } = getNewOnOffReadings(
		selectedAutoCorrectedReadingData || [],
		selectedDatFile,
		useMv
	);

	const oldData = getOldOnOffReadings(
		convertedReadingsForSelectedFile,
		selectedAutoCorrectedReadingData,
		useMv,
		selectedDatFile
	);

	const preProcessedGPSReadingsChartData = getPreprocessedReadings(
		preprocessedGPSReadings,
		selectedDatFile,
		useMv
	);

	return {
		oldData,
		preProcessedGPSReadings: preProcessedGPSReadingsChartData,
		newData,
		defaultMv
	};
}

export function getSelectedDatFile(datFiles, selectedDatFile) {
	if (typeof selectedDatFile === 'undefined' || !datFiles) {
		return null;
	}

	return datFiles[selectedDatFile];
}

export function getSelectedDatFileIndex(datFiles, selectedDatFile) {
	if (!selectedDatFile || !datFiles) {
		return null;
	}

	for (let i = 0; i < datFiles.length; i += 1) {
		const datFile = datFiles[i];

		if (datFile.fileName === selectedDatFile) {
			return i;
		}
	}

	throw new Error(`Unkown selected Dat file for "getSelectedDatFile"`);
}

export function getNextChartData(datFiles, selectedDatFile, useMv) {
	const currentData = getSelectedDatFile(datFiles, selectedDatFile);
	const { endStn } = currentData;

	const nextDatFile = getSelectedDatFile(datFiles, selectedDatFile + 1);
	const {
		readings: nextChartDataAll /* ,
		mv: defaultMv */
	} = getOnOffReadingsFromData(null, nextDatFile, useMv);

	if (nextDatFile && nextDatFile.reverse) {
		nextChartDataAll.reverse();
	}

	let nextChartData;
	if (nextDatFile && nextDatFile.fileName.toLowerCase().indexOf('.csv') > -1) {
		nextChartData = nextChartDataAll.slice(0, 160).map(data => ({
			...data
		}));
	} else {
		nextChartData = nextChartDataAll.slice(0, 160).map(data => ({
			...data,
			stationId: data.stationId + endStn
		}));
	}
	return { nextDatFile, nextChartData };
}

export function getPreviousChartData(datFiles, selectedDatFile, useMv) {
	const previousDatFile = getSelectedDatFile(datFiles, selectedDatFile - 1);
	if (!previousDatFile) {
		return {};
	}

	const { interval, endStn } = previousDatFile;

	const {
		readings: previousChartDataAll /* ,
		mv: defaultMv */
	} = getOnOffReadingsFromData(null, previousDatFile, useMv);

	if (previousDatFile && previousDatFile.reverse) {
		previousChartDataAll.reverse();
	}

	const previousChartDataSlice = previousChartDataAll.slice(-160);

	const lastSliceDataStationId = previousChartDataSlice.length * interval;
	const stationIdBuffer = endStn - lastSliceDataStationId;
	const previousChartData = previousChartDataSlice.map((chartData, i) => ({
		...chartData,
		stationId: stationIdBuffer + i * interval
	}));

	return { previousDatFile, previousChartData };
}

export const getUserEdits = (selectedDatFile, spikeEditorUserChangesByFile) => {
	const { fileName } = selectedDatFile;

	if (!fileName) {
		return [];
	}

	const userChanges = spikeEditorUserChangesByFile[fileName];

	if (!userChanges || userChanges.length === 0) {
		return [];
	}

	return userChanges;
};

export const getUserEditsExist = userEdits => {
	if (userEdits && userEdits.length > 0) {
		return true;
	}

	return false;
};

// TODO: We should not depend on oldData anymore
export const getNewDataOrDefault = (newData, oldData) => {
	if (newData && newData.length) {
		return newData;
	}

	return oldData;
};
