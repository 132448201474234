import { MiscUtils } from 'aegion_common_utilities';

import { small, medium, large, fullLeft } from '../actions/tabs';

import { canUseStationAlignmentTool } from '../../config/betaTesting';

import TAB_IDS from './utils/tabIds';

const defaultHasFiles = false;
const defaultBetaTestingTabsAllowed = [];
const defaultCisSurvey = false;

const getDefaultTabs = hasFiles => {
	return [
		{
			id: TAB_IDS.UPLOADS,
			title: 'Uploads',
			sizes: [hasFiles ? medium : large],
			defaultSize: hasFiles ? medium : large
		},
		{
			id: TAB_IDS.DOWNLOADS,
			title: 'Downloads',
			sizes: [hasFiles ? medium : large],
			defaultSize: hasFiles ? medium : large
		},
		{
			id: TAB_IDS.SURVEY_FILES,
			title: 'Survey Files',
			sizes: [small, medium, large],
			defaultSize: medium
		},
		{
			id: TAB_IDS.READINGS,
			title: 'Readings',
			sizes: [small, medium, large],
			defaultSize: small
		},

		{
			id: TAB_IDS.OVERLAPS,
			title: 'Overlaps',
			sizes: [medium],
			defaultSize: medium
		},
		{
			id: TAB_IDS.SPIKE_EDITOR,
			title: 'Spike Editor',
			sizes: [fullLeft, small],
			defaultSize: fullLeft
		},
		{
			id: TAB_IDS.QAQC,
			title: 'QAQC',
			sizes: [medium],
			defaultSize: medium
		}
	];
};

const isUserAllowedToViewStationAlignmentTab = betaTestingTabsAllowed => {
	if (!MiscUtils.isProd()) {
		return true;
	}

	if (betaTestingTabsAllowed.includes(TAB_IDS.STATION_ALIGNMENT)) {
		return true;
	}

	return canUseStationAlignmentTool();
};

const getStationAlignmentTab = betaTestingTabsAllowed => {
	if (!isUserAllowedToViewStationAlignmentTab(betaTestingTabsAllowed)) {
		return [];
	}

	return [
		{
			id: TAB_IDS.STATION_ALIGNMENT,
			title: 'Station Alignment',
			sizes: [medium],
			defaultSize: medium
		}
	];
};

const getBetaTabs = betaTestingTabsAllowed => {
	return [...getStationAlignmentTab(betaTestingTabsAllowed)];
};

const getCISTabs = () => {
	const skipTab = {
		id: TAB_IDS.SKIPS,
		title: 'Skips',
		sizes: [medium, large],
		defaultSize: medium
	};

	const segmentGapTab = {
		id: TAB_IDS.SEGMENT_GAP_ANALYSIS,
		title: 'Segment Gap Analysis',
		sizes: [small],
		defaultSize: small
	};

	return [skipTab, segmentGapTab];
};

const getTabs = (state = {}) => {
	const {
		cisSurvey = defaultCisSurvey,
		betaTestingTabsAllowed = defaultBetaTestingTabsAllowed,
		hasFiles = defaultHasFiles
	} = state;

	const defaultTabs = [
		...getDefaultTabs(hasFiles),
		...getBetaTabs(betaTestingTabsAllowed)
	];
	if (!cisSurvey) {
		return defaultTabs;
	}

	return [...defaultTabs, ...getCISTabs()];
};

function getTabIndexFromHasFiles(hasFiles) {
	if (hasFiles) {
		return 2; // Map page
	}

	return 0; // Upload page
}

const defaultTabs = getTabs();
const defaultTabIndex = getTabIndexFromHasFiles(defaultHasFiles);
const defaultMode = defaultTabs[defaultTabIndex];

const defaultState = {
	hasIndex: defaultHasFiles,
	tabs: defaultTabs,
	tabIndex: defaultTabIndex,
	mode: defaultMode,
	secondaryPanelSize: defaultMode.defaultSize,
	previousTabs: [],
	startOfCurrentTab: new Date(),
	cisSurvey: defaultCisSurvey,
	betaTestingTabsAllowed: defaultBetaTestingTabsAllowed,
	hasFiles: defaultHasFiles
};

const setHasFiles = (state, hasFiles) => {
	const { hasFiles: previousHasFiles } = state;
	// There was not actually a change here
	if (hasFiles === previousHasFiles) {
		return state;
	}

	const tabIndex = getTabIndexFromHasFiles(hasFiles);
	const newStateBeforeGettingTabs = {
		...state,
		hasFiles,
		tabIndex
	};

	const tabs = getTabs(newStateBeforeGettingTabs);
	const mode = tabs[tabIndex];

	return {
		...newStateBeforeGettingTabs,
		mode,
		tabs,
		secondaryPanelSize: mode.defaultSize
	};
};

const setLeftPanelSize = (state, size) => {
	return {
		...state,
		secondaryPanelSize: size
	};
};

const setCISSurvey = (state, cisSurvey) => {
	const { mode } = state;

	const newStateBeforeGettingTabs = {
		...state,
		mode,
		cisSurvey
	};

	const tabs = getTabs(newStateBeforeGettingTabs);

	return {
		...newStateBeforeGettingTabs,
		tabs
	};
};

const setTabIndex = (state, tabIndex) => {
	const { tabs, secondaryPanelSize } = state;
	const mode = tabs[tabIndex];

	const newSecondaryPanelSize = mode.sizes.includes(secondaryPanelSize)
		? secondaryPanelSize
		: mode.defaultSize;
	return {
		...state,
		tabIndex,
		mode,
		secondaryPanelSize: newSecondaryPanelSize
	};
};

const setTabById = (state, tabId) => {
	if (!state || !state.tabs) {
		return state;
	}

	let indexOfTab = state.tabs.findIndex(tab => tab.id === tabId);

	if (indexOfTab === -1) {
		indexOfTab = getTabIndexFromHasFiles(state.hasFiles);
	}

	return setTabIndex(state, indexOfTab);
};

const setUserChangedTabId = (state, previousTabs) => {
	return {
		...state,
		previousTabs,
		startOfCurrentTab: new Date()
	};
};

const setBetaTestingTabs = (state, betaTestingTabsAllowed) => {
	const newStateBeforeGettingTabs = {
		...state,
		betaTestingTabsAllowed
	};

	const tabs = getTabs(newStateBeforeGettingTabs);

	return {
		...newStateBeforeGettingTabs,
		tabs
	};
};

const tabs = (state = defaultState, action) => {
	switch (action.type) {
		case 'CISV_UI_TABS_SET_HAS_FILES': {
			return setHasFiles(state, action.hasFiles);
		}

		case 'CISV_UI_TABS_SET_TAB_INDEX':
			return setTabIndex(state, action.tabIndex);

		case 'CISV_UI_TABS_SET_CIS_SURVEY': {
			return setCISSurvey(state, action.cisSurvey);
		}

		case 'CISV_UI_TABS_SET_TAB_SIZE':
			return setLeftPanelSize(state, action.size);

		case 'CISV_UI_TABS_SET_TAB_BY_ID':
			return setTabById(state, action.tabId);

		case 'CISV_UI_TABS_SET_USER_CHANGED_TAB':
			return setUserChangedTabId(state, action.previousTabs);

		case 'CISV_UI_TABS_SET_BETA_TESTING_TABS_ALLOWED':
			return setBetaTestingTabs(state, action.betaTestingTabsAllowed);
		default:
			return state;
	}
};

export default tabs;
