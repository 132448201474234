import {
	getMinYRange,
	lineStyleIndexByValue,
	getTraces,
	getDefaultLayout,
	getSelectActionsFromNewChartData,
	getPaginationStatus
} from './spike-editor-spike-graph.worker.utils';

import {
	OPTIMUM_ZOOM_IN_FEET,
	defaultState
} from '../util/spike-editor-config';

const X_AXIS_GRID_WIDTH = 1;
const Y_AXIS_GRID_WIDTH = 1;
const X_AXIS_GRID_COLOR = '#c1c1c7';
const Y_AXIS_GRID_COLOR = '#c1c1c7';
const X_AXIS_GRID_SHOW = true;
const Y_AXIS_GRID_SHOW = true;
const X_AXIS_GRID_STEP = 0;
const Y_AXIS_GRID_STEP = 0;

const DEFAULT_MIN_CRITERIA_COLOR = {
	r: '255',
	g: '0',
	b: '0'
};

const DEFAULT_MAX_CRITERIA_COLOR = {
	r: '100',
	g: '100',
	b: '100'
};

// This is just so we don't have to export a default property below (which would not work as a web worker)
export const unusedFunction = () => {};

const mergeLayout = (originalLayout, newLayout = {}) => {
	const otherYaxisProperties = {
		showgrid: Y_AXIS_GRID_SHOW,
		gridwidth: Y_AXIS_GRID_WIDTH,
		gridcolor: Y_AXIS_GRID_COLOR
	};
	const otherXaxisProperties = {
		autorange: false,
		showgrid: X_AXIS_GRID_SHOW,
		gridwidth: X_AXIS_GRID_WIDTH,
		gridcolor: X_AXIS_GRID_COLOR
	};
	const mergedLayout = {
		...originalLayout,
		yaxis: {
			...originalLayout.yaxis,
			...(newLayout.yaxis || {}),
			...otherYaxisProperties
		},
		xaxis: {
			...originalLayout.xaxis,
			...(newLayout.xaxis || {}),
			...otherXaxisProperties
		}
	};

	return mergedLayout;
};

const getDefaultCriterialLines = props => {
	if (props.criteriaLines) {
		return {
			criteriaLines: props.criteriaLines,
			minCritValue: props.minCritValue,
			maxCritValue: props.maxCritValue
		};
	}

	return {
		criteriaLines: {
			minimum: {
				color: DEFAULT_MIN_CRITERIA_COLOR,
				lineStyle: lineStyleIndexByValue('dashdot'), // Solid
				width: 1,
				value: -850
			},
			maximum: {
				color: DEFAULT_MAX_CRITERIA_COLOR,
				lineStyle: lineStyleIndexByValue('dashdot'), // Solid
				width: 1,
				value: -1200
			}
		},
		minCritValue: -850,
		maxCritValue: -1200
	};
};

// TODO: See where we are setting criteria lines - do not overwrite lines
export const getDefaultStateFromProps = props => {
	const {
		oldData,
		newData,
		useMv,
		previousChartData,
		nextChartData,
		selectedDatFile,
		selectedDatFileIndex,
		preProcessedGPSReadings,
		layout: layoutFromProps,
		currentYRange,
		settings,
		datFileChanged,
		...otherProps
	} = props;

	const defaultLayout = layoutFromProps || getDefaultLayout(props);

	const { xaxis } = defaultLayout;

	const minYRange = getMinYRange(props);
	// Only reset yRange if this is the first time loading
	const yRange = currentYRange || [0, minYRange];

	const currentXRange = xaxis.range.slice(); // Make a copy
	const newLayout = {
		yaxis: {
			range: yRange
		},
		xaxis: {
			range: currentXRange
		}
	};
	const newState = {
		...defaultState,
		...otherProps,
		preProcessedGPSReadings,
		selectedDatFile,
		selectedDatFileIndex,
		oldData,
		newData,
		previousChartData,
		nextChartData,
		currentXRange,
		layout: mergeLayout(defaultLayout, newLayout),
		currentYRange: yRange,
		manualYRange: [0, minYRange],
		useMv, // TODO: I don't like this but we need to update yRange within this componenet for now
		...getDefaultCriterialLines(props),
		displayMinColorPicker: false,
		displayMaxColorPicker: false,
		feetPerPage: OPTIMUM_ZOOM_IN_FEET,
		gridAxesSetting: {
			xaxis: {
				width: X_AXIS_GRID_WIDTH,
				color: X_AXIS_GRID_COLOR,
				show: X_AXIS_GRID_SHOW,
				step: X_AXIS_GRID_STEP
			},
			yaxis: {
				width: Y_AXIS_GRID_WIDTH,
				color: Y_AXIS_GRID_COLOR,
				show: Y_AXIS_GRID_SHOW,
				step: Y_AXIS_GRID_STEP
			}
		},
		highlightedPoint: []
	};

	const stateFromNewTraces = {
		...newState,
		...getTraces(newState, settings)
	};

	const selectActions = getSelectActionsFromNewChartData(
		newState,
		newState.autoCorrectionInProcess
	);

	let finalLayoutStateAdditions = {};
	// If the dat file has changed, we need to recompute the layout again based on the traces created above
	if (datFileChanged) {
		const layoutUpdateFromNewFiles = getDefaultLayout(stateFromNewTraces);

		const newYrange = [0, getMinYRange(stateFromNewTraces)];

		const newXrange = layoutUpdateFromNewFiles.xaxis.range.slice(); // Make a copy

		const yRangeLayout = {
			yaxis: {
				range: newYrange
			},
			xaxis: {
				range: newXrange
			}
		};

		finalLayoutStateAdditions = {
			layout: mergeLayout(layoutUpdateFromNewFiles, yRangeLayout),
			currentXRange: newXrange,
			currentYRange: newYrange
		};
	}

	const traceState = {
		...stateFromNewTraces,
		selectActions,
		...finalLayoutStateAdditions
	};

	return {
		...traceState,
		paginationStatus: getPaginationStatus(traceState)
	};
};
