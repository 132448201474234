/* eslint-disable import/prefer-default-export */
import JobUtil from '../../../utils/JobUtil/JobUtil';
import { setGlobalData } from './job';
import { selectGlobalDataAndData } from '../../selectors/globalData';
import { displayDatSaveMessage } from '../message-box';

export const thunkSetAlignedTo = ({ timeuuid } = {}) => (
	dispatch,
	getState
) => {
	const { globalData: currGlobalData, data } = selectGlobalDataAndData(
		getState()
	);
	const globalData = { ...currGlobalData };
	if (!timeuuid) {
		delete globalData.alignedTo;
	} else {
		globalData.alignedTo = timeuuid;
	}
	if (globalData.alignedTo !== currGlobalData.alignedTo) {
		dispatch(setGlobalData(globalData, data));
	}
};

export const thunkSaveAlignedTo = () => (dispatch, getState) => {
	const { globalData } = selectGlobalDataAndData(getState());

	const propsToUpdate = {
		job: globalData.timeuuid,
		alignedTo: globalData.alignedTo
	};
	JobUtil.update(propsToUpdate, error => {
		if (error) {
			dispatch(displayDatSaveMessage(error, null)); ///
		}
	});
};
