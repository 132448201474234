/* eslint-disable no-param-reassign */
import createBaseSurveyMeta from '../_BASE';
import {
	computeUuid,
	computeFormattedStationId,
	computePipeDepth,
	computeAC,
	computeSpatial,
	computeDistance,
	assumeIsInMV
} from '../../util/readings.parse.computeProps';
import { createComputeYMinYMax } from '../../util/yMinYMax';
import { defaultCreateSimplifiedReadings } from '../../util/general';

import { INVERT_Y_AXIS, GAP_THRESHOLD } from './constants';
import { shouldStandardizeToMV, standardizeToMV } from './util/mvfuzzy';
import {
	generateReadingKeyStructures,
	createGetReadingKeys,
	createGetReadingKeyProps,
	createGetReadingKeysByBcPriority,
	createGetSurveyColor
} from './util/readingKeys';
import { createGetColumns } from './util/columns';
import { createTransformToCsvJson } from '../util';

export default _survey => {
	const READING_KEYS = generateReadingKeyStructures();
	const getReadingKeys = createGetReadingKeys(READING_KEYS);
	const getReadingKeyProps = createGetReadingKeyProps(READING_KEYS);
	const getReadingKeysByBcPriority = createGetReadingKeysByBcPriority(
		READING_KEYS
	);
	const getSurveyColor = createGetSurveyColor(getReadingKeys);

	const computeYMinYMax = createComputeYMinYMax(getReadingKeys());

	// SKIP READING

	const isSkipReading = reading => {
		const readingKeys = getReadingKeys();
		return reading && readingKeys.every(rk => !reading[rk.value]);
	};

	// HAS GAP

	const hasGap = (currReading, prevReading) => {
		const hasGapByStationId =
			!!prevReading && currReading.id - prevReading.id > GAP_THRESHOLD;
		const hasGapByDistance = +currReading.dst > GAP_THRESHOLD;
		return hasGapByStationId || hasGapByDistance;
	};

	// INTERPOLATE

	const interpolate = (
		// eslint-disable-next-line no-unused-vars
		_prevDepolReading,
		// eslint-disable-next-line no-unused-vars
		_nextDepolReading,
		// eslint-disable-next-line no-unused-vars
		_alignToReading
	) => {};

	// COMPUTE PROPS - to be used by readings.parse
	const computeSawtooth = (survey, r) => {
		r.sawtooth = r.sawtooth || r.off || r.on;
		if (r.off === 0 || r.on === 0) {
			survey.cntZeros = (survey.cntZeros || 0) + 1;
		}
	};

	const computeHilo = r => {
		r.low = r.low || 0;
	};

	// @deprecated for all datasets loaded after 1/23/2023 (conditional mv fuzzy removal);
	const computeAggregates = (currReading, aggregates = {}) => {
		aggregates.cntVoltReadings =
			(aggregates.cntVoltReadings || 0) +
			(assumeIsInMV(currReading.on) ? 0 : 1);
		aggregates.cntVoltReadings =
			(aggregates.cntVoltReadings || 0) +
			(assumeIsInMV(currReading.off) ? 0 : 1);

		return aggregates;
	};

	const computeProps = (survey, prevReading, currReading, currIdx) => {
		computeUuid(currReading, currIdx);
		computeFormattedStationId(currReading);
		computePipeDepth(currReading);
		computeAC(currReading);
		computeSpatial(currReading);
		computeDistance(prevReading, currReading);
		computeSawtooth(survey, currReading);
		computeHilo(currReading);
		computeYMinYMax(survey, currReading);
	};

	// SIMPLIFIED READINGS - creates a smaller data set to be used for rendering polyline on map
	const createSimplifiedReadings = defaultCreateSimplifiedReadings;

	// MAIN

	const getColumns = createGetColumns(_survey);
	const transformToCsvJson = createTransformToCsvJson(getColumns);

	const meta = {
		...createBaseSurveyMeta(_survey),
		isChartInversed: INVERT_Y_AXIS,

		getReadingKeys,
		getReadingKeyProps,
		selectReadingKeysByBcPriority: getReadingKeysByBcPriority,
		getSurveyColor,

		getColumns,
		transformToCsvJson,
		fkey: 'on',
		isSkipReading,
		hasGap,
		canUseInterpolate: false,
		canUseNormalize: false,
		isInterpolateReference: true,
		isNormalizeReference: true,
		interpolate,
		computeProps,
		computeYMinYMax,
		shouldCheckMVOnLoad: true,
		shouldStandardizeToMV,
		standardizeToMV,
		checkForSawtooth: true,
		computeAggregates,
		createSimplifiedReadings,
		canHaveCompositeDepol: true
	};

	return meta;
};
