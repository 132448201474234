/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import jQuery from 'jquery';
import cuid from 'cuid';

import ajax from './utils/ajax';
import { hasAccessToken } from './utils/token';
import { transformServerReadings } from './utils/surveyByteArray';
import scanlineMapUtils from './mapUtils';

import { filterOutBadReadings } from './utils/reading';
import {
	makeTempS3FilePermanent,
	deleteMyProject,
	getProjectData,
	getExternalProjectData,
	saveProjectData,
	deleteProjectData
} from './redux/Line/slices/ProjectData/util/api/ajaxUtil';
import { createProject } from './redux/Line/slices/Project/util/api/ajaxUtil';
import ActionPlanFileDownloadWebSocket from './components/GenericActionPlanReport/util/FileDownloadWebSocket';
import ClosureFileDownloadWebSocket from './components/GenericActionPlanReport/util/ClosureFileDownloadWebSocket';
import ImportFileWebsocket from './websockets/ImportFileWebsocket';
import S3Signed from './utils/S3Signed';
import readingsRequestQueue from './scanlineUtils.ReadingsRequestQueue';
import { getChartId } from './utils/chart';
import promisifyAjax from './utils/promisifyAjax';
import { getCustomer, getUserName } from '../util/user';
import { universalSortSurveys } from './utils/surveys.sort';

/**
 * CONSTANTS
 */
const ON_OFF_KEYS = ['ON_OFF', 'ON-OFF'];

/**
 * HELPERS
 */

const _helpIsCIS = survey =>
	survey && ['ON', 'ON_OFF', 'ON-OFF'].indexOf(survey.survey_subtype) > -1;

const _helpIsDepol = survey =>
	survey && ['DEPOL'].indexOf(survey.survey_subtype) > -1;

// const _helpIsNative = survey =>
// 	survey && ['NATIVE'].indexOf(survey.survey_subtype) > -1;

const computeIsMergedWithCIS = (survey1, survey2) => {
	let cisSurvey;
	let toMergeSurvey;

	if (_helpIsCIS(survey1)) {
		cisSurvey = survey1;
	} else if (_helpIsDepol(survey1)) {
		toMergeSurvey = survey1;
	}

	if (_helpIsCIS(survey2)) {
		cisSurvey = survey2;
	} else if (_helpIsDepol(survey2)) {
		toMergeSurvey = survey2;
	}

	if (cisSurvey && toMergeSurvey) {
		toMergeSurvey.isMergedWithCIS = true;
	}
};

const canHackJobId = (survey = {}) => survey.survey_subtype === 'DEPOL';

const hackJobId = (paramSurveys = []) => {
	const surveys = [...paramSurveys];

	let idx1 = 0;
	while (idx1 < surveys.length) {
		const currentSurvey = surveys[idx1];

		if (currentSurvey.job_id) {
			idx1 += 1;
		} else {
			currentSurvey.job_id = currentSurvey.survey_guid;
			currentSurvey.jobId = currentSurvey.job_id;
			idx1 += 1;
			if (idx1 < surveys.length) {
				let idx2 = idx1;
				while (idx2 < surveys.length) {
					const subSurvey = surveys[idx2];
					if (
						currentSurvey.job_number &&
						subSurvey.job_number === currentSurvey.job_number
					) {
						subSurvey.job_id = canHackJobId(subSurvey)
							? currentSurvey.job_id
							: subSurvey.survey_guid;
						subSurvey.jobId = subSurvey.job_id;
						computeIsMergedWithCIS(currentSurvey, subSurvey);
					}
					idx2 += 1;
				}
			}
		}
	}

	return surveys;
};

const surveySortCompare = (s1, s2) => {
	if (s1.is_external_survey && !s2.is_external_survey) {
		return 1;
	}

	if (!s1.is_external_survey && s2.is_external_survey) {
		return -1;
	}

	if (scanlineMapUtils.isCis(s1.id) && !scanlineMapUtils.isCis(s2.id)) {
		return -1;
	}

	if (!scanlineMapUtils.isCis(s1.id) && scanlineMapUtils.isCis(s2.id)) {
		return 1;
	}

	if (scanlineMapUtils.isCis(s1.id) && scanlineMapUtils.isCis(s2.id)) {
		const isOnOffS1 = ON_OFF_KEYS.indexOf(s1.survey_subtype) > -1;
		const isOnOffS2 = ON_OFF_KEYS.indexOf(s2.survey_subtype) > -1;

		if (isOnOffS1 && !isOnOffS2) {
			return -1;
		}

		if (!isOnOffS1 && isOnOffS2) {
			return 1;
		}
	}

	if (s1.start_date && s2.start_date) {
		const s1StartDate = new Date(s1.start_date).getTime();
		const s2StartDate = new Date(s2.start_date).getTime();

		if (s1StartDate === s2StartDate) {
			return 0;
		}

		return s1StartDate > s2StartDate ? -1 : 1;
	}

	if (s1.start_date && !s2.start_date) {
		return -1;
	}

	if (!s1.start_date && s2.start_date) {
		return 1;
	}

	return 0;
};

/**
 * MAIN
 */

const scanlineUtils = {
	getJobs(customer, callback) {
		if (hasAccessToken()) {
			ajax(`/jobs/${encodeURI(customer)}`, null, 'GET', (err, res) => {
				if (err) {
					callback(err);
				} else {
					callback(null, res);
				}
			});
		}
	},

	getCustomers(callback) {
		if (hasAccessToken()) {
			ajax('/customers', null, 'GET', (err, res) => {
				if (err) {
					callback(err);
				} else {
					callback(null, res);
				}
			});
		}
	},

	getProjectManagers(customer, callback) {
		if (hasAccessToken()) {
			ajax(`/projectmanagers/${customer}`, null, 'GET', (err, res) => {
				if (err) {
					callback(err);
				} else {
					callback(null, res);
				}
			});
		}
	},

	getViewAsList(callback) {
		if (hasAccessToken()) {
			ajax(
				`/list`,
				null,
				'GET',
				(err, res) => {
					if (err) {
						callback(err);
					} else {
						callback(null, res);
					}
				},
				undefined,
				'viewAs'
			);
		}
	},

	getViewAsApprovers(callback) {
		if (hasAccessToken()) {
			ajax(
				`/approvers`,
				null,
				'GET',
				(err, res) => {
					if (err) {
						callback(err);
					} else {
						callback(null, res);
					}
				},
				undefined,
				'viewAs'
			);
		}
	},

	getSurveys2(
		{ lineId, lineName, projectId, projectName, ignoreIds },
		callback
	) {
		if (hasAccessToken()) {
			const paramData = JSON.stringify({
				lineId,
				lineName,
				projectId,
				projectName,
				ignoreIds
			});
			ajax(`/surveys`, paramData, 'POST', (err, res) => {
				if (err) {
					callback(err);
				} else {
					const depolSurveys = res.surveys.filter(
						item => item.survey_subtype === 'DEPOL'
					);

					let surveys = universalSortSurveys(res.surveys);

					surveys = hackJobId(surveys);
					surveys.forEach(item => {
						item.internalId = item.id;
						item.x = item.id;
						item.id = scanlineMapUtils.createSurveyId(
							item.survey_type,
							item.survey_subtype,
							item.job_id
						);
						item.CHART_ID = getChartId(item);
					});
					// attach other data to the surveys
					surveys.forEach((item, i) => {
						const withDepol =
							depolSurveys.filter(
								depolSurvey => depolSurvey.job_id === item.job_id
							).length > 0;
						const isCIS = scanlineMapUtils.isCis(item.id);
						// Only the first item should be checked (visible) by default - even if there are multiple CIS surveys - just show one
						if (i === 0) {
							item.isDefaultSurvey = true;
							item.checked = true;
						} else {
							item.checked = false;
						}
						item.isFirstSurveyOfGroup = true;
						item.withThreshold = isCIS;
						item.withDepol = isCIS && withDepol;
						// attach metadata
						scanlineMapUtils.initSurveyMeta(item);
					});
					callback(null, res);
				}
			});
		} else {
			callback('Session expired. Please log back in.');
		}
	},

	getSurveyS3Keys({ surveyExternalId }, callback) {
		if (hasAccessToken()) {
			const url = `/surveys/s3Keys/${surveyExternalId}`;
			ajax(url, undefined, 'GET', (err, res) => {
				if (err) {
					callback(err);
				} else {
					res.s3Keys = res.s3Keys || [];
					callback(null, res);
				}
			});
		} else {
			callback('Session expired. Please log back in.');
		}
	},

	/**
	 * Not yet implemented
	 * @param {*} param0
	 * @param {*} callback
	 */
	fetchSurveys({ lineId }, callback) {
		if (hasAccessToken()) {
			ajax(`/surveys/${encodeURI(lineId)}`, null, 'GET', (err, res) => {
				if (err) {
					callback(err);
				} else {
					const depolSurveys = res.surveys.filter(
						item => item.survey_subtype === 'DEPOL'
					);
					res.surveys.forEach(item => {
						item.internalId = item.id;
						item.id = scanlineMapUtils.createSurveyId(
							item.survey_type,
							item.survey_subtype,
							item.job_id
						);
						item.CHART_ID = getChartId(item);
					});
					res.surveys.sort(surveySortCompare);
					// attach other data to the surveys
					res.surveys.forEach((item, i) => {
						const withDepol =
							depolSurveys.filter(
								depolSurvey => depolSurvey.job_id === item.job_id
							).length > 0;
						const isCIS = scanlineMapUtils.isCis(item.id);
						// Only the first item should be checked (visible) by default - even if there are multiple CIS surveys - just show one
						if (i === 0) {
							item.isDefaultSurvey = true;
							item.checked = true;
						} else {
							item.checked = false;
						}
						item.withThreshold = isCIS;
						item.withDepol = isCIS && withDepol;
						// attach metadata
						scanlineMapUtils.initSurveyMeta(item);
					});
					callback(null, res);
				}
			});
		}
	},

	// used for populating maps with polygons while readings are loaded.
	// copy / pasted from fieldline >> jobs list >> utils.getLines
	getMapLines(surveyGuid, lineName, index, callback) {
		if (hasAccessToken()) {
			ajax(
				`/lines/${encodeURI(surveyGuid)}/${encodeURIComponent(
					lineName
				)}?index=${encodeURI(index)}`,
				null,
				'GET',
				(err, res) => {
					if (err) {
						callback(err);
					} else {
						callback(null, res);
					}
				},
				undefined,
				'fieldline'
			);
		}
	},

	getReadings(
		surveyId,
		type,
		subtype,
		index,
		lineName = 'ignore linename',
		callback
	) {
		if (hasAccessToken()) {
			const urlParts = {
				surveyId,
				lineName, // @todo - this param needs to be removed from sls - currently required by share link authorizer on backend
				type,
				subtype,
				index
			};
			readingsRequestQueue.queueRequest(this.getReadingsV2.bind(this), {
				...urlParts,
				callback
			});
		}
	},

	getReadingsV2({
		testOnly,
		surveyId,
		lineName = 'ignore linename', // @todo - this param needs to be removed from sls
		type,
		subtype,
		index,
		callback
	}) {
		// console.log(lineName);
		// debugger;

		const query = `ver=V2&&f=blob&type=${encodeURI(type)}&subtype=${encodeURI(
			subtype
		)}&index=${encodeURI(index)}`;
		const url = `/jobData/${encodeURI(surveyId)}/${encodeURIComponent(
			lineName
		)}?${query}`;

		const processResponse = (err, res) => {
			if (err) {
				callback(err);
			} else {
				res.data = transformServerReadings(
					{
						readings: res.data.readings,
						comments: res.data.comments,
						additionalProps: res.data.additionalProps,
						surveyType: subtype
					},
					{ appName: 'scanline' }
				);
				callback(null, res);
			}
		};

		if (!testOnly) {
			ajax(url, null, 'GET', processResponse);
		} else {
			ajax(url, null, 'GET', () => {});
		}
	},

	// return scanlineUtils.getUnsupportedReadings(
	// 	surveyGuid,
	// 	type,
	// 	subtype,
	// 	s3Key,

	getUnsupportedReadings(
		lineId,
		projectId,
		surveyGuid,
		type,
		subtype,
		s3Key,
		callback
	) {
		if (hasAccessToken()) {
			// @wbnote - this is in use
			const parseDatFile = () => {
				return new Promise((resolve, reject) => {
					const queryParams = [`key=${encodeURI(s3Key)}`];

					if (projectId) {
						queryParams.push(`projectId=${encodeURI(projectId)}`);
					}

					if (lineId) {
						queryParams.push(`lineId=${encodeURI(lineId)}`);
					}

					if (subtype) {
						queryParams.push(`surveyTypeKey=${encodeURI(subtype)}`);
					}

					const query = `?${queryParams.join('&')}`;
					// console.info('>>> PARSE FILE - DAT / CSV');
					// debugger;
					ajax(
						`/parseFile${query}`,
						null,
						'GET',
						(err, res) => {
							// console.info('>>> ON - parse file - DAT / CSV');
							// debugger;
							if (err) {
								reject(err);
							} else {
								res.data = filterOutBadReadings(res.data);
								resolve(res);
							}
						},
						false,
						'parseFile'
					);
				});
			};
			parseDatFile()
				.then(({ url }) => S3Signed.fetchJSON(url))
				.then(res => {
					callback(null, res);
				})
				.catch(err => {
					callback(err);
				});
		}
	},

	universalDataUpload(key, projectId, lineId, surveyTypeKey, options) {
		return scanlineUtils.universalDataImportV2(
			key,
			projectId,
			lineId,
			surveyTypeKey,
			options
		);

		// const { callback } = options;
		// const importDatFile = () => {
		// 	return new Promise((resolve, reject) => {
		// 		const queryParams = [`key=${encodeURIComponent(key)}`];

		// 		if (projectId) {
		// 			queryParams.push(`projectId=${encodeURIComponent(projectId)}`);
		// 		}

		// 		if (lineId) {
		// 			queryParams.push(`lineId=${encodeURIComponent(lineId)}`);
		// 		}

		// 		if (surveyTypeKey) {
		// 			queryParams.push(
		// 				`surveyTypeKey=${encodeURIComponent(surveyTypeKey)}`
		// 			);
		// 		}

		// 		const query = `?${queryParams.join('&')}`;
		// 		// console.info('>>> PARSE FILE - DAT / CSV');
		// 		ajax(
		// 			`/import-file${query}`,
		// 			null,
		// 			'GET',
		// 			(err, res) => {
		// 				if (err) {
		// 					reject(err);
		// 				} else {
		// 					resolve({ success: res.success });
		// 				}
		// 			},
		// 			false,
		// 			'parseFile'
		// 		);
		// 	});
		// };

		// // eslint-disable-next-line no-unreachable
		// return importDatFile().then(res => callback(undefined, res)).catch(err => callback(err));
	},

	universalDataImportV2(key, projectId, lineId, surveyTypeKey, options = {}) {
		const { viewAsUserId, callback } = options;

		const payload = {
			params: {
				key,
				projectId,
				lineId,
				surveyTypeKey
			},
			authParams: {
				customer: getCustomer(),
				userId: getUserName(),
				viewAsUserId
			}
		};

		ImportFileWebsocket.startImport(payload, key, callback);
	},

	downloadShapefile(data, callback) {
		// @wbnote - this is in use
		if (hasAccessToken()) {
			const filename = `${cuid()}.json`;
			const getSignedUploadUrl = () => {
				return new Promise((resolve, reject) => {
					const query = `?file=${encodeURI(filename)}`;
					ajax(`/getSignedUploadUrl${query}`, null, 'GET', (err, res) => {
						if (err) {
							reject(err);
						} else {
							resolve(res.url);
						}
					});
				});
			};
			const uploadGeojson = signedUrl => {
				return new Promise((resolve, reject) => {
					const key = `temp-uploads/${filename}`;
					const jsonData = JSON.stringify(data);
					// create blob from string
					const blobData = new Blob([jsonData]);
					// create file from blob
					// @todo: this does not appear to be accessible on all browsers, return/review/and resolve.
					// eslint-disable-next-line no-undef
					const file = new File([blobData], filename);
					// debugger;
					jQuery.ajax({
						type: 'PUT',
						url: signedUrl,
						data: file,
						processData: false, // Don't process the data
						contentType: 'binary/octet-stream',
						success() {
							// debugger;
							resolve(key);
						},
						error(_XMLHttpRequest, textStatus) {
							reject(textStatus);
						}
					});
				});
			};
			const downloadShapefile = key => {
				return new Promise((resolve, reject) => {
					const query = `?key=${encodeURI(key)}`;
					ajax(`/downloadShapefile${query}`, null, 'GET', (err, res) => {
						if (err) {
							reject(err);
						} else {
							resolve(res);
						}
					});
				});
			};
			getSignedUploadUrl()
				.then(uploadGeojson)
				.then(downloadShapefile)
				.then(res => {
					// NOTE this forces the browser to download
					window.location.href = res.url;
					callback(null, res);
				})
				.catch(err => {
					callback(err);
				});
		}
	},

	downloadReport(jobId, lineName, data, callback) {
		if (hasAccessToken()) {
			const downloadReport = () => {
				return new Promise((resolve, reject) => {
					const url = `/generateReport/${encodeURI(jobId)}/${encodeURIComponent(
						lineName
					)}`;
					const paramData = JSON.stringify(data);
					const httpCallBack = (err, res) => {
						if (err) {
							reject(err);
						} else {
							resolve(res);
						}
					};
					ajax(
						url,
						paramData,
						'POST',
						httpCallBack,
						undefined,
						'scanlineReport'
					);
				});
			};

			downloadReport()
				.then(res => {
					// NOTE this forces the browser to download
					if (res.url) {
						window.location.href = res.url;
					}
					callback(null, res);
				})
				.catch(err => {
					callback(err);
				});
		}
	},

	downloadV2Report(data, additionalProps, callback) {
		const { meta, ...params } = data;
		const payload = {
			...meta,
			...params
		};

		if (hasAccessToken()) {
			ActionPlanFileDownloadWebSocket.startDownload(
				payload,
				additionalProps,
				callback
			);
		}
	},

	downloadV2ClosureReport(data, callback) {
		const { meta, ...params } = data;
		const payload = {
			...meta,
			...params
		};
		if (hasAccessToken()) {
			ClosureFileDownloadWebSocket.startDownload(payload, callback);
		}
	},

	saveReportData(jobId, lineName, data, callback) {
		if (hasAccessToken()) {
			const saveReportData = () => {
				return new Promise((resolve, reject) => {
					ajax(
						`/saveReportData/${encodeURI(jobId)}/${encodeURIComponent(
							lineName
						)}`,
						JSON.stringify(data),
						'POST',
						(err, res) => {
							if (err) {
								reject(err);
							} else {
								resolve(res);
							}
						}
					);
				});
			};
			saveReportData()
				.then(res => {
					callback(null, res);
				})
				.catch(err => {
					callback(err);
				});
		}
	},

	getProjectReportData(projectId, projectName, type, callback) {
		// @wbnote - is this in use? @inuse?
		debugger;
		if (hasAccessToken()) {
			const query = `?type=${encodeURIComponent(type)}`;
			ajax(
				`/getProjectReportData/${encodeURI(projectId)}/${encodeURIComponent(
					projectName
				)}${query}`,
				null,
				'GET',
				(err, res) => {
					if (err) {
						callback(err);
					} else {
						callback(null, res);
					}
				},
				undefined,
				'scanlineReport'
			);
		}
	},
	getAppStateV2({ lineId, projectId }, callback) {
		const lineOrProjectKey = projectId ? 'project' : 'line';
		const lineOrProjectId = projectId || lineId;

		if (hasAccessToken()) {
			ajax(
				`/getAppStateV2/${lineOrProjectKey}/${encodeURIComponent(
					lineOrProjectId
				)}`,
				null,
				'GET',
				(err, res) => {
					if (err) {
						callback(err);
					} else {
						callback(null, res);
					}
				}
			);
		}
	},

	saveAppStateV2({ lineId, projectId, data }) {
		return promisifyAjax(({ reject, onComplete }) => {
			const lineOrProjectKey = projectId ? 'project' : 'line';
			const lineOrProjectId = projectId || lineId;

			const urlPathParts = [
				'saveAppStateV2',
				lineOrProjectKey,
				lineOrProjectId
			];
			const urlPath = `/${urlPathParts.map(v => encodeURI(v)).join('/')}`;

			if (!hasAccessToken()) {
				reject(new Error('saveAppStateV2 - Does not have access token'));
			} else {
				ajax(urlPath, JSON.stringify(data), 'POST', onComplete);
			}
		});
	},

	generateShareLink(url, data, callback) {
		if (hasAccessToken()) {
			const generateShareLink = () => {
				return new Promise((resolve, reject) => {
					const postData = JSON.stringify({ url, data });
					ajax('/generateShareLink', postData, 'POST', (err, res) => {
						if (err) {
							reject(err);
						} else {
							resolve(res);
						}
					});
				});
			};
			generateShareLink()
				.then(res => {
					callback(null, res);
				})
				.catch(err => {
					callback(err);
				});
		}
	},

	/**
	 * @param {uuid} jobId - the uuid to identify a survey (unfortunately used interchangeably for job and survey at the moment)
	 * @param {uuid} lineId - the uuid of the line
	 * @param {integer} projectId - the integer id from psql
	 * @param {string} type - report type ACTION_PLAN or CLOSURE
	 * @param {function} callback = standard ajax callback of form (err, res) => {}
	 */
	getReportDataV1_5(jobId, lineId, projectId, reportType, callback) {
		const lineOrProjectKey = projectId ? 'project' : 'line';
		const lineOrProjectId = projectId || lineId;

		if (hasAccessToken()) {
			ajax(
				`/getReportDataV1_5/${encodeURI(lineOrProjectKey)}/${encodeURI(
					lineOrProjectId
				)}/survey/${encodeURI(jobId)}/${encodeURI(reportType)}`,
				null,
				'GET',
				(err, res) => {
					if (err) {
						callback(err);
					} else {
						callback(null, res);
					}
				}
			);
		}
	},

	saveReportDataV1_5(
		{ jobId, lineId, projectId, reportType, reportStatusKey, data },
		callback
	) {
		const lineOrProjectKey = projectId ? 'project' : 'line';
		const lineOrProjectId = projectId || lineId;

		const urlPathParts = [
			'saveReportDataV1_5',
			lineOrProjectKey,
			lineOrProjectId,
			'survey',
			jobId,
			reportType,
			reportStatusKey
		];
		const urlPath = `/${urlPathParts.map(v => encodeURI(v)).join('/')}`;

		if (hasAccessToken()) {
			const callSaveReportData = () =>
				new Promise((resolve, reject) => {
					ajax(urlPath, JSON.stringify(data), 'POST', (err, res) => {
						if (err) {
							reject(err);
						} else {
							resolve(res);
						}
					});
				});

			callSaveReportData()
				.then(res => {
					callback(null, res);
				})
				.catch(err => {
					callback(err);
				});
		}
	},
	getReportDataV2(jobId, lineId, projectId, reportType, callback) {
		const lineOrProjectKey = projectId ? 'project' : 'line';
		const lineOrProjectId = projectId || lineId;

		if (hasAccessToken()) {
			ajax(
				`/getReportDataV2/${encodeURI(lineOrProjectKey)}/${encodeURI(
					lineOrProjectId
				)}/survey/${encodeURI(jobId)}/${encodeURI(reportType)}`,
				null,
				'GET',
				(err, res) => {
					if (err) {
						callback(err);
					} else {
						callback(null, res);
					}
				}
			);
		}
	},

	saveReportDataV2({
		jobId,
		lineId,
		projectId,
		reportType,
		reportStatusKey,
		data
	}) {
		return promisifyAjax(({ reject, onComplete }) => {
			const lineOrProjectKey = projectId ? 'project' : 'line';
			const lineOrProjectId = projectId || lineId;

			const urlPathParts = [
				'saveReportDataV2',
				lineOrProjectKey,
				lineOrProjectId,
				'survey',
				jobId,
				reportType,
				reportStatusKey
			];
			const urlPath = `/${urlPathParts.map(v => encodeURI(v)).join('/')}`;

			if (!hasAccessToken()) {
				reject(new Error('saveReportDataV2 - Does not have access token'));
			} else {
				ajax(urlPath, JSON.stringify(data), 'POST', onComplete);
			}
		});
	},

	hasChangesReportDataV2({
		jobId,
		lineId,
		projectId,
		reportType,
		reportStatusKey,
		data
	}) {
		return promisifyAjax(({ reject, onComplete }) => {
			const lineOrProjectKey = projectId ? 'project' : 'line';
			const lineOrProjectId = projectId || lineId;

			const urlPathParts = [
				'hasChangesReportDataV2',
				lineOrProjectKey,
				lineOrProjectId,
				'survey',
				jobId,
				reportType,
				reportStatusKey
			];
			const urlPath = `/${urlPathParts.map(v => encodeURI(v)).join('/')}`;

			if (!hasAccessToken()) {
				reject(
					new Error('hasChangesReportDataV2 - Does not have access token')
				);
			} else {
				ajax(urlPath, JSON.stringify(data), 'POST', onComplete);
			}
		});
	},

	getRating(jobId, lineName, callback) {
		if (hasAccessToken()) {
			ajax(
				`/getRating/${encodeURI(jobId)}/${encodeURIComponent(lineName)}`,
				null,
				'GET',
				(err, res) => {
					if (err) {
						callback(err);
					} else {
						callback(null, res);
					}
				}
			);
		}
	},

	saveRating(jobId, lineName, data, callback) {
		if (hasAccessToken()) {
			ajax(
				`/saveRating/${encodeURI(jobId)}/${encodeURIComponent(lineName)}`,
				JSON.stringify(data),
				'POST',
				(err, res) => {
					if (err) {
						callback(err);
					} else {
						callback(null, res);
					}
				}
			);
		}
	},

	createProject(jobId, data, lineName, callback) {
		return createProject(jobId, data, lineName, callback);
	},

	getProjectData(jobId, callback) {
		// @wbnote - is this in use? @inuse?
		debugger;
		return getProjectData(jobId, callback);
	},

	getExternalProjectData(projectId, callback) {
		// @wbnote - is this in use? @inuse?
		debugger;
		return getExternalProjectData(projectId, callback);
	},

	saveProjectData(jobId, projectId, projectData, callback) {
		// @wbnote - is this in use? @inuse?
		debugger;
		return saveProjectData(jobId, projectId, projectData, callback);
	},

	deleteMyProject(projectId, callback) {
		// @wbnote - this is in use
		return deleteMyProject(projectId, callback);
	},

	deleteProjectData(projectDataIds, callback) {
		// @wbnote - this is in use
		return deleteProjectData(projectDataIds, callback);
	},

	moveTempS3File(key, callback) {
		// @wbnote - this is in use
		return makeTempS3FilePermanent(key, callback);
	},

	// @deprecated - use filterOutBadReadings(data) instead
	filterReadings(readings) {
		return filterOutBadReadings(readings);
	},

	getSurveyDisplayName(survey, numberOfCISSurveys) {
		const {
			survey_type: surveyType,
			survey_subtype: surveySubType,
			displayName,
			id: surveyId,
			description,
			isDefaultSurvey
		} = survey;

		const cleanDescription = description && description.trim();

		if (displayName) {
			return displayName;
		}
		const displayNameParts = [];

		if (scanlineMapUtils.isCis(surveyId)) {
			// Distinguish this from other CIS surveys
			if (isDefaultSurvey && numberOfCISSurveys > 1) {
				displayNameParts.push('*');
			}

			displayNameParts.push('CIS');
		} else if (surveyType.indexOf('CIS') > -1 && surveySubType === 'DOC') {
			displayNameParts.push('DoC');
		} else if (surveyType === 'CIS') {
			displayNameParts.push(surveySubType);
		} else {
			displayNameParts.push(surveyType);
		}

		if (cleanDescription) {
			displayNameParts.push(`(${cleanDescription})`);
		}

		return displayNameParts.join(' ');
	},

	searchProjectFilters(searchTerm, callback) {
		if (hasAccessToken()) {
			ajax(
				`/searchProjectFilter/${searchTerm}`,
				null,
				'GET',
				(err, res) => {
					if (err) {
						callback(err);
					} else {
						callback(null, res);
					}
				},
				undefined,
				'scanlineReport'
			);
		}
	},

	// example url: https://local-api.aip.aegion.com/scanline/filter/term
	searchFilters(searchTerm, callback) {
		if (hasAccessToken()) {
			ajax(
				`/filter/${encodeURIComponent(searchTerm)}`,
				null,
				'GET',
				(err, res) => {
					if (err) {
						callback(err);
					} else {
						callback(null, res);
					}
				}
			);
		}
	},

	fetchJobs(filtersObj, callback) {
		if (hasAccessToken()) {
			ajax('/jobs', JSON.stringify(filtersObj), 'POST', (err, res) => {
				if (err) {
					callback(err);
				} else {
					callback(null, res);
				}
			});
		}
	},

	fetchLines(filtersObj, callback) {
		if (hasAccessToken()) {
			ajax('/lines', JSON.stringify(filtersObj), 'POST', (err, res) => {
				if (err) {
					callback(err);
				} else {
					callback(null, res);
				}
			});
		}
	},

	fetchProjects(reqObj, callback) {
		if (hasAccessToken()) {
			ajax(
				'/getProjects',
				JSON.stringify(reqObj),
				'POST',
				(err, res) => {
					if (err) {
						callback(err);
					} else {
						callback(null, res);
					}
				},
				false,
				'scanline'
			);
		}
	},

	fetchProjectsNameList(callback) {
		if (hasAccessToken()) {
			ajax(
				'/getProjectsNameList',
				null,
				'GET',
				(err, res) => {
					if (err) {
						callback(err);
					} else {
						callback(null, res);
					}
				},
				false,
				'scanline'
			);
		}
	},

	fetchProjectsWithFilters(reqObj, callback) {
		if (hasAccessToken()) {
			ajax(
				'/getProjectsWithFilters',
				JSON.stringify(reqObj),
				'POST',
				(err, res) => {
					if (err) {
						callback(err);
					} else {
						callback(null, res);
					}
				},
				false,
				'scanlineReport'
			);
		}
	},

	getProjectsMetadata(reqObj, callback) {
		// @wbnote - this is in use
		if (hasAccessToken()) {
			const _callback = (err, res) => {
				if (err) {
					callback(err);
				} else {
					callback(null, res);
				}
			};
			ajax(
				'/jobsmetadata',
				JSON.stringify(reqObj),
				'POST',
				_callback,
				undefined,
				'fieldline'
			);
		}
	},

	getJobsMetadata(jobs, callback) {
		if (hasAccessToken()) {
			const _callback = (err, res) => {
				if (err) {
					callback(err);
				} else {
					callback(null, res);
				}
			};
			ajax(
				'/jobsmetadata',
				JSON.stringify(jobs),
				'POST',
				_callback,
				undefined,
				'fieldline'
			);
		}
	},

	addLineTag(lineId, tag, callback) {
		const payload = {
			lineId,
			tagName: tag
		};
		if (hasAccessToken()) {
			const _callback = (err, res) => {
				if (err) {
					callback(err);
				} else {
					callback(null, res);
				}
			};
			ajax(
				'/createTag',
				JSON.stringify(payload),
				'POST',
				_callback,
				false,
				'scanlineTags'
			);
		}
	},

	deleteLineTag(lineId, tag, callback) {
		const payload = {
			lineId,
			tagName: tag
		};
		if (hasAccessToken()) {
			const _callback = (err, res) => {
				if (err) {
					callback(err);
				} else {
					callback(null, res);
				}
			};
			ajax(
				'/deleteTag',
				JSON.stringify(payload),
				'POST',
				_callback,
				false,
				'scanlineTags'
			);
		}
	},

	getTags(callback) {
		if (hasAccessToken()) {
			const _callback = (err, res) => {
				if (err) {
					callback(err);
				} else {
					callback(null, res);
				}
			};
			ajax(
				`/getTagsByCustomer/${getCustomer()}`,
				undefined,
				'GET',
				_callback,
				false,
				'scanlineTags'
			);
		}
	},

	requestActionPlanApproval(
		{
			defaultSurveyId,
			url,
			requestDate,
			requestSentTo,
			originallySubmittedByUserid
		},
		callback
	) {
		if (hasAccessToken()) {
			const _callback = (err, res) => {
				if (err) {
					callback(err);
				} else {
					callback(null, res);
				}
			};

			const payload = {
				surveyId: defaultSurveyId,
				link: url,
				date: requestDate,
				to: requestSentTo,
				cc: [originallySubmittedByUserid]
			};

			ajax(
				'/submit-action-plan',
				JSON.stringify(payload),
				'POST',
				_callback,
				false,
				'scanlineWorkflow'
			);
		}
	},

	requestActionPlanCancel(
		{
			defaultSurveyId,
			url,
			requestDate,
			requestSentTo,
			originallySubmittedByUserid
		},
		callback
	) {
		if (hasAccessToken()) {
			const _callback = (err, res) => {
				if (err) {
					callback(err);
				} else {
					callback(null, res);
				}
			};

			const payload = {
				surveyId: defaultSurveyId,
				link: url,
				date: requestDate,
				to: requestSentTo,
				cc: [originallySubmittedByUserid]
			};

			ajax(
				'/cancel-action-plan',
				JSON.stringify(payload),
				'POST',
				_callback,
				false,
				'scanlineWorkflow'
			);
		}
	},

	requestActionPlanReject(
		{
			defaultSurveyId,
			url,
			requestDate,
			requestSentTo,
			originallySubmittedByUserid
		},
		callback
	) {
		if (hasAccessToken()) {
			const _callback = (err, res) => {
				if (err) {
					callback(err);
				} else {
					callback(null, res);
				}
			};

			const payload = {
				surveyId: defaultSurveyId,
				link: url,
				date: requestDate,
				to: [originallySubmittedByUserid],
				cc: requestSentTo
			};

			ajax(
				'/reject-action-plan',
				JSON.stringify(payload),
				'POST',
				_callback,
				false,
				'scanlineWorkflow'
			);
		}
	},

	requestActionPlanApproved(
		{
			defaultSurveyId,
			url,
			requestDate,
			requestSentTo,
			originallySubmittedByUserid
		},
		callback
	) {
		if (hasAccessToken()) {
			const _callback = (err, res) => {
				if (err) {
					callback(err);
				} else {
					callback(null, res);
				}
			};

			const payload = {
				surveyId: defaultSurveyId,
				link: url,
				date: requestDate,
				to: [originallySubmittedByUserid],
				cc: requestSentTo
			};

			ajax(
				'/approved-action-plan',
				JSON.stringify(payload),
				'POST',
				_callback,
				false,
				'scanlineWorkflow'
			);
		}
	},

	listReportsV1_5({ offset, limit } = {}, callback) {
		const url = `/listReportsV1_5/offset/${offset}/limit/${limit}`;

		const onHttpComplete = (err, res) => {
			if (err) {
				callback(err);
			} else {
				callback(null, res);
			}
		};

		if (hasAccessToken()) {
			ajax(url, undefined, 'GET', onHttpComplete);
		}
	},
	getReportsCountV1_5: async () => {
		return new Promise((resolve, reject) => {
			const url = '/getReportsCountV1_5';

			const onHttpComplete = (err, res) => {
				if (err) {
					reject(err);
				} else {
					resolve(res);
				}
			};

			if (hasAccessToken()) {
				ajax(url, undefined, 'GET', onHttpComplete);
			}
		});
	},

	listReportsV2({ offset, limit } = {}, callback) {
		const url = `/listReportsV2/offset/${offset}/limit/${limit}`;

		const onHttpComplete = (err, res) => {
			if (err) {
				callback(err);
			} else {
				callback(null, res);
			}
		};

		if (hasAccessToken()) {
			ajax(url, undefined, 'GET', onHttpComplete);
		}
	},

	getReportsCountV2: async () => {
		return new Promise((resolve, reject) => {
			const url = '/getReportsCountV2';

			const onHttpComplete = (err, res) => {
				if (err) {
					reject(err);
				} else {
					resolve(res);
				}
			};

			if (hasAccessToken()) {
				ajax(url, undefined, 'GET', onHttpComplete);
			}
		});
	},

	canIViewAs: userId => {
		return new Promise((resolve, reject) => {
			const url = `/can-i/${encodeURIComponent(userId)}`;
			const onHttpComplete = (err, res) => {
				if (err) {
					reject(err);
				} else {
					const { canViewAs } = res;
					if (canViewAs) {
						resolve(true);
					} else {
						resolve(false);
					}
				}
			};

			if (hasAccessToken()) {
				ajax(url, undefined, 'GET', onHttpComplete, false, 'viewAs');
			}
		});
	},

	isPm: () => {
		return new Promise((resolve, reject) => {
			const url = `/is-pm`;

			const onHttpComplete = (err, res) => {
				if (err) {
					reject(err);
				} else {
					const { isPm } = res;
					resolve(isPm);
				}
			};

			if (hasAccessToken()) {
				ajax(url, undefined, 'GET', onHttpComplete, false, 'viewAs');
			}
		});
	},

	emailCustomerSupport: (
		{ cc, subject, appendToSubject, content, docType = 'TEXT' },
		{ ignoreError = false }
	) => {
		return new Promise((resolve, reject) => {
			const url = `/email-customer-support`;
			const currentUrl =
				document && document.location ? document.location.href : undefined;

			const payload = JSON.stringify({
				cc,
				subject,
				appendToSubject,
				content,
				docType,
				currentUrl
			});

			const onHttpComplete = (err, res) => {
				if (err) {
					if (!ignoreError) {
						reject(err);
					} else {
						resolve(false);
					}
				} else {
					const { message } = res;
					resolve(message);
				}
			};

			if (hasAccessToken()) {
				ajax(url, payload, 'POST', onHttpComplete, false, 'notifications');
			}
		});
	}
};

export default scanlineUtils;
