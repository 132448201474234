// eslint-disable-next-line import/no-webpack-loader-syntax, import/extensions, import/no-unresolved
// import SpikeEditorWorker from 'workerize-loader?inline!./workers/spike-editor-spike-graph.worker.js';
import { getDefaultStateFromProps } from './workers/spike-editor-spike-graph.worker';

import LocalStorageUtil from '../../utils/LocalStorageUtil';

// const spikeEditorWorker = SpikeEditorWorker();

const setDefaultStateFromProps = newState => ({
	type: 'CISV_SPIKE_CHART_SET_DEFAULT',
	newState
});

const _hasDatFileChanged = (oldSelectedDatFile, newSelectedDatFile) => {
	if (!oldSelectedDatFile && newSelectedDatFile) {
		return true;
	}

	const {
		endStn: oldEndStn,
		startStn: oldStartStn,
		reverse: oldReverse,
		fileName: oldFileName
	} = oldSelectedDatFile;
	const {
		endStn: newEndStn,
		startStn: newStartStn,
		reverse: newReverse,
		fileName: newFileName
	} = newSelectedDatFile;

	if (oldFileName !== newFileName) {
		return true;
	}

	if (oldReverse !== newReverse) {
		return true;
	}

	if (oldStartStn !== newStartStn) {
		return true;
	}

	if (oldEndStn !== newEndStn) {
		return true;
	}

	return false;
};

export const updateDefaultStateFromProps = props => async (
	dispatch,
	getState
) => {
	const { cisview } = getState();
	const { spikeEditorSpikeGraph } = cisview;

	const settings = {
		useChartDotsConfig: LocalStorageUtil.spikeEditor.getGraphDotVisibility()
	};
	const newProps = { ...props, settings };

	// Prefill state with what used to be there
	Object.keys(spikeEditorSpikeGraph).forEach(key => {
		const value = spikeEditorSpikeGraph[key];
		const valueFromProp = newProps[key];

		if (value && valueFromProp === undefined) {
			newProps[key] = value;
		}
	});
	const datFileChanged = _hasDatFileChanged(
		spikeEditorSpikeGraph.selectedDatFile,
		props.selectedDatFile
	);

	newProps.datFileChanged = datFileChanged;

	const newState = await getDefaultStateFromProps(newProps);
	dispatch(setDefaultStateFromProps(newState));
};

export const onSpikeGraphMvChanged = newState => ({
	type: 'CISV_SPIKE_CHART_SET_USE_MV',
	newState
});

export const changeAxisStepHandler = (axis, value) => ({
	type: 'CISV_SPIKE_GRAPH_CHANGE_STEP_HANDLER',
	axis,
	value
});

export const relayoutFromChangeAxisStepHandler = axis => ({
	type: 'CISV_SPIKE_GRAPH_RELAYOUT_FRROM_CHANGE_STEP_HANDLER',
	axis
});

export const updateCriteriaOption = payload => ({
	type: 'CISV_SPIKE_GRAPH_UPDATE_CRITERIA_OPTION',
	payload
});

export const handleManualYRange = (index, value) => ({
	type: 'CISV_SPIKE_GRAPH_HANDLE_MANUAL_Y_RANGE',
	index,
	value
});

export const updateXAxisTicks = () => ({
	type: 'CISV_SPIKE_GRAPH_UPDATE_X_AXIS_TICKS'
});

export const onRelayout = event => ({
	type: 'CISV_SPIKE_CHART_ON_RELAYOUT',
	event
});

export const doOptimumZoom = () => ({
	type: 'CISV_SPIKE_CHART_DO_OPTIMUM_ZOOM'
});

export const performPaginate = dir => ({
	type: 'CISV_SPIKE_CHART_PERFORM_PAGINATE',
	dir
});

export const changeAxisColorHandler = (axis, name, value) => ({
	type: 'CISV_SPIKE_CHART_CHANGE_AXIS_COLOR',
	axis,
	name,
	value
});

export const changeAxisWidthHandler = (axis, name, value) => ({
	type: 'CISV_SPIKE_CHART_CHANGE_AXIS_WIDTH',
	axis,
	name,
	value
});

export const changeAxisShowHideHandler = (axis, name, value) => ({
	type: 'CISV_SPIKE_CHART_CHANGE_AXIS_SHOW_HIDE',
	axis,
	name,
	value
});

export const changeTraceColorHandler = (traceName, color) => ({
	type: 'CISV_SPIKE_CHART_CHANGE_TRACE_COLOR',
	traceName,
	color
});

export const setFeetChanged = feetPerPage => ({
	type: 'CISV_SPIKE_CHART_SET_FEET_CHANGED',
	feetPerPage
});

export const zoomPerFeet = feet => ({
	type: 'CISV_SPIKE_CHART_ZOOM_PER_FEET',
	feet
});

export const feetChanged = feet => ({
	type: 'CISV_SPIKE_CHART_FEET_CHANGED',
	feet
});

// TODO: Move to web worker
export const setSelectedDatFile = (
	selectedDatFile,
	selectedDatFileIndex,
	oldData,
	preProcessedGPSReadings,
	newData,
	previousChartData,
	nextChartData,
	autoCorrectionInProcess,
	previousDatFile,
	nextDatFile
) => ({
	type: 'CISV_SPIKE_CHART_SET_SELECTED_DAT_FILE',
	selectedDatFile,
	selectedDatFileIndex,
	oldData,
	preProcessedGPSReadings,
	newData,
	previousChartData,
	nextChartData,
	autoCorrectionInProcess,
	previousDatFile,
	nextDatFile
});

export const handleModalClose = () => ({
	type: 'CISV_SPIKE_CHART_CLOSE_MODAL'
});

export const handleModalShow = () => ({
	type: 'CISV_SPIKE_CHART_OPEN_MODAL'
});

export const setSelectAction = selectedSelectActionValue => ({
	type: 'CISV_SPIKE_CHART_SET_SELECT_ACTION',
	selectedSelectActionValue
});

export const setReadingPointHighlighted = readingIndex => ({
	type: 'CISV_SPIKE_CHART_SET_READING_POINT_HIGHLIGHTED',
	readingIndex
});
export const resetReadingPointHighlighted = readingIndex => ({
	type: 'CISV_SPIKE_CHART_RESET_READING_POINT_HIGHLIGHTED',
	readingIndex
});

export const setReadingRowSelected = (datIndex, readingIndex) => ({
	type: 'CISV_SPIKE_CHART_ON_ROW_SELECT',
	datIndex,
	readingIndex
});

export const resetReadingRowSelected = (datIndex, readingIndex) => ({
	type: 'CISV_SPIKE_CHART_ON_ROW_UNSELECT',
	datIndex,
	readingIndex
});

export const zoomToSpikeEditorPoint = readingIndex => ({
	type: 'CISV_SPIKE_CHART_ZOOM_TO_READING_INDEX',

	readingIndex
});

export const resetSelectedPointsByDatFile = () => ({
	type: 'CISV_SPIKE_CHART_UNSELECT_ALL_PER_DAT_FILE'
});

export const changeTraceVisibility = curveNumber => ({
	type: 'CISV_SPIKE_CHART_CHANGE_TRACE_VISIBILITY',
	curveNumber
});

export const updateShowCommentsConfig = selectedCommentConfig => ({
	type: 'CISV_SPIKE_CHART_UPDATE_SHOW_COMMENTS_CONFIG',
	selectedCommentConfig
});

export const updateGraphDotsShowOption = () => ({
	type: 'CISV_SPIKE_CHART_UPDATE_SHOW_GRAPH_DOTS_CONFIG'
});

export const updateShowOriginalLineConfig = selectedOriginalLineConfig => ({
	type: 'CISV_SPIKE_CHART_UPDATE_SHOW_ORIGINAL_LINES_CONFIG',
	selectedOriginalLineConfig
});

export const revertNavigationChange = () => ({
	type: 'CISV_SPIKE_CHART_REVERT_NAVIGATION_CHANGE'
});

export const setImmutableLayoutCopy = layout => ({
	type: 'CISV_SPIKE_CHART_SET_IMMUTABLE_LAYOUT',
	layout
});

export const setSurveyType = surveyType => ({
	type: 'CISV_SPIKE_CHART_SET_SURVEY_TYPE',
	surveyType
});
